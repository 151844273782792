import React, {useContext, useReducer} from 'react'
import {Spinner} from 'reactstrap'
import reducer from './reducer'
import initState from './state'
import {actionReduxDevtoolJump} from './action'
import ReduxDevtool from '../../../../devtools/ReduxDevtool'
export const SelectedVariantContext = React.createContext()

let isInitState = false
export const SelectedVariantProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initState)

    if (!isInitState) {
        isInitState = true
        ReduxDevtool.init(initState)
    }

    ReduxDevtool.subscribeOnce('DISPATCH', (message) => {
        const {state: jumpState = ''} = message
        dispatch(actionReduxDevtoolJump(jumpState))
    })

    const value = {
        state: state || {},
        dispatch,
    }

    if (state.loading)
        return (
            <div className="LoadingWrapper">
                <Spinner style={{color: '#15cd72'}} />
            </div>
        )

    return <SelectedVariantContext.Provider value={value}>{children}</SelectedVariantContext.Provider>
}

export const useSelectedVariantContext = () => {
    const value = useContext(SelectedVariantContext)

    if (value === undefined) {
        throw new Error('useSelectedVariantContext must be called within SelectedVariantContext')
    }

    return value
}

