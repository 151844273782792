import React, {useEffect, useState} from 'react'
import {Spinner} from 'reactstrap'
import {usePreviewArtworks} from '../../../../../hooks/usePreviewArtworks'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import PreviewImageItem from './PreviewImageItem'
import {actionUpdateGalleryImage, actionSetRunningImageCompress} from '../../../context/SelectedVariantContext/action'

const MockupImagePreviewContainer = () => {
    const {
        dispatch,
        state: {display, previewImages},
    } = useSelectedVariantContext()

    const [images, setImages] = useState([])
    const [select, setSelect] = useState(0)

    const {fetchPreviews, pending} = usePreviewArtworks()

    useEffect(() => {
        if (display !== 'pricing') return
        fetchPreviews()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display])

    useEffect(() => {
        const images = previewImages.reduce((acc, preview) => [...acc, ...preview.images], [])
        setImages(images)
        if (!!images.length) {
            dispatch(actionUpdateGalleryImage(images[0]))
        }
    }, [previewImages, display, dispatch])

    useEffect(() => {
        dispatch(actionSetRunningImageCompress(pending))
    }, [pending, dispatch])

    return (
        <div className="MockupImagePreviewContainer">
            {pending ? (
                <div className="LoadingContainer text-center">
                    <Spinner style={{color: '#007bff'}} />
                </div>
            ) : (
                <>
                    <span className="px-3">Set a featured image</span>
                    <div className="ListImagePreview p-3">
                        {!!images.length &&
                            images.map((image, index) => (
                                <PreviewImageItem
                                    key={index}
                                    image={image}
                                    onselect={() => {
                                        setSelect(index)
                                        const selectedImage = images[index]
                                        dispatch(actionUpdateGalleryImage(selectedImage))
                                    }}
                                    isSelected={select === index}
                                />
                            ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default MockupImagePreviewContainer

