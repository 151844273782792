import React from 'react'
import {Input, FormFeedback} from 'reactstrap'

const FacebookInput = ({textHeading, name, value, error = '', editable, onChange, onBlur, invalid}) => {
    const invalidProps = invalid ? invalid : error !== ''
    return (
        <div className="mb-3">
            <h3 className="Title fs-20 mb-3">{textHeading}</h3>
            <Input
                autoComplete="off"
                invalid={invalidProps}
                type="text"
                name={name}
                value={value}
                className="form-control"
                onChange={(e) => onChange(e)}
                disabled={!editable}
                onBlur={(e) => onBlur && onBlur(e.target.value ? e.target.value.trim() : '', e.target.name)}
            />
            {error !== '' && <FormFeedback>{error}</FormFeedback>}
        </div>
    )
}

export default FacebookInput

