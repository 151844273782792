import React from 'react'
import TabsContainer from './tabs/TabsContainer'
import Publish from '../publish/Publish'

const UploadContainer = () => {

    return (
        <div className={`UploadContainer NewVersion${'Front' === 'All' ? ' AllAreaTab' : ''}`}>
            <TabsContainer/>
            <div className="MockupGeneratorPublish">
                <Publish />
            </div>
        </div>
    )
}

export default UploadContainer
