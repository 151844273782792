import debug from 'debug'

const logger = debug('ReduxDevtool')

/**
 * Debugging tool for Context API
 * @class ReduxDevtool
 * @description Set `localStorage.debug = 'ReduxDevtool:*'` in a browser to enable debug logs
 */
class ReduxDevtool {
    instance
    logger = logger
    prefix = `[ReduxDevtool]` // Prefix for console log
    subscribers = {} // Subscribers for Redux Devtools

    /**
     * Constructor
     * @param prefix {string}
     */
    constructor(prefix = `[ReduxDevtool]`) {
        this.prefix = prefix
        this.logger = debug(prefix)
    }

    /**
     * Get Redux Devtools instance
     * @returns {*}
     * @private
     */
    __getInstance() {
        return this.instance
    }

    /**
     * Set Redux Devtools instance
     * @param instance {any}
     * @private
     */
    __setInstance(instance) {
        this.instance = instance
    }

    /**
     * Get Redux Devtools instance
     * @returns {any}
     * @private
     */
    __getReduxDevtoolExtensionInstance() {
        return window.__REDUX_DEVTOOLS_EXTENSION__
    }

    /**
     * Connect to Redux Devtools
     */
    connect() {
        if (!this.__getReduxDevtoolExtensionInstance()) {
            this.logger(`Redux Devtools is not installed`)
            return
        }

        if (this.__getInstance()) {
            this.logger(`Redux Devtools is already connected`)
            return
        }

        this.logger(`Connecting to Redux Devtools`)
        const devtool = this.__getReduxDevtoolExtensionInstance().connect({
            trace: true,
        })

        devtool.subscribe((message) => {
            this.logger(`Received message from Redux Devtools: ${message.type}`, message)
            if (this.subscribers[message.type]) {
                this.subscribers[message.type].forEach(fn => {
                    this.logger(`Calling subscriber for message: ${message.type}`)
                    fn(message)
                })
            }
        })
        // devtool.init()
        this.__setInstance(devtool)
    }

    /**
     * Initialize Redux Devtools
     */
    init(state) {
        if (!this.__getInstance()) {
            this.logger(`Redux Devtools is not connected`)
            return
        }

        this.logger(`Initializing Redux Devtools`)
        this.__getInstance().init(state)
    }

    /**
     * Disconnect from Redux Devtools
     */
    disconnect() {
        if (!this.__getInstance()) {
            this.logger(`Redux Devtools is not connected`)
            return
        }

        this.__getInstance().disconnect()
        this.__setInstance(null)
    }

    /**
     * Send a message to Redux Devtools
     * @param name {string}
     * @param args {any}
     */
    send(name, args) {
        if (!this.__getInstance()) {
            this.logger(`Redux Devtools is not connected`)
            return
        }

        this.logger(`Sending message to Redux Devtools: ${name}`)
        this.__getInstance().send(name, args)
    }

    /**
     * Subscribe only once to Redux Devtools
     * @param name {string}
     * @param fn {function}
     */
    subscribeOnce(name, fn) {
        if (!this.__getInstance()) {
            this.logger(`Redux Devtools is not connected`)
            return
        }

        this.subscribers[name] = this.subscribers[name] || []
        this.logger(`subscribeOnce to Redux Devtools: ${name}, subscribers: ${this.subscribers[name].length}`)

        if (this.subscribers[name].length <= 0) {
            this.subscribers[name].push(fn)
        } else {
            this.subscribers[name][0] = fn
        }
    }

    /**
     * Use Reducer with Redux Devtools  (for development)
     * @param state {any}
     * @param action {any}
     * @param fn {function}
     * @returns {*}
     */
    useReducerWithReduxDevtool(state, action, fn) {
        // this.send(action.type, state)
        const newState = fn(state, action)
        this.send(action.type, newState)

        return newState
    }
}

const reduxDevtool = new ReduxDevtool()
export default reduxDevtool
