import React from 'react'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import { actionSetCampaignDetail } from '../../../context/SelectedVariantContext/action'
import { searchCollections } from '../../../../../services/ProductServices'

const CampaignCollections = ({collections, dispatch}) => {
    let timeout = null

    const _fetchCollections = async (title) => {
        try {
            const {data, success, message} = await searchCollections(title)

            if (!success) throw new Error(message)

            return data.collections.map(collection => ({
                value: collection._id,
                label: collection.name,
                isDisabled: collection.type === 'automated',
                type: collection.type,
                facebookPixelID: collection.facebook_pixel_id
            }))
        } catch (e) {
            return [{label: e.message, value: 'error', type: 'error', isDisabled: true}]
        }
    }

    const _handleLoadOptions = inputValue => {
        if (timeout) clearTimeout(timeout)
        return new Promise(resolve => {
            timeout = setTimeout(() => {
                resolve(_fetchCollections(inputValue))
            }, 500)
        })
    }

    const _handleSelectOption = (value) => {
        dispatch(actionSetCampaignDetail({field: 'collections', value}))
    }

    const _handleOptionLabel = (option, context) => {
        if (option.type === 'error') {
            return <div className="text-danger">{option.label}</div>
        }

        return (
            <div>
                <div>{option.label}</div>
                {option.type === 'automated' && <span className="fs-14">Automated collection</span>}
            </div>
        )
    }

    return (
        <div className="CampaignCollections Section">
            <div className="Heading">Collections</div>

            <AsyncSelect
                isMulti
                placeholder="Search collections..."
                value={collections || []}
                noOptionsMessage={() => null}
                defaultOptions
                inputId="inputProductCollections"
                onChange={_handleSelectOption}
                formatOptionLabel={_handleOptionLabel}
                loadOptions={_handleLoadOptions}
            />
        </div>
    )
}

export default React.memo(CampaignCollections)
