import {createStoreAPI} from './CreateStoreAPIService'

export const searchCollections = (name) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: '/v2/collections/search',
        method: 'POST',
        data: {
            name,
        },
    })
}

export const activateProductPOD = (productId) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: `products/${productId}/activate`,
        method: 'POST',
    })
}

export const createProductPODV4 = (campaignData) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: '/v4/products/pod/create',
        method: 'POST',
        data: campaignData,
    })
}

export const deleteProduct = (id) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: `/products/${id}/delete`,
        method: 'POST',
    })
}

export const uploadImageGallery = (productId, galleryUrls) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: `products/${productId}/gallery/by-link`,
        method: 'POST',
        data: galleryUrls,
    })
}

export const getVariantImages = (id) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: `/variants/${id}/images`,
        method: 'GET',
    })
}

export const getProductFacebookPixel = ({productID, collectionIDs, tags}) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: '/products/facebook-pixel-id',
        method: 'POST',
        data: {
            product_id: productID,
            collections: collectionIDs,
            tags: tags,
        },
    })
}
