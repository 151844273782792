import {useMemo} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {useEditorSides} from './useEditorSides'

export function useEditorBackground() {
    const {
        state: {selectedAttribute, selectedSide},
    } = useSelectedVariantContext()

    const {isOptionalSide} = useEditorSides()

    const background = useMemo(() => {
        if (!selectedAttribute?._id || !selectedSide || isOptionalSide) return {}

        return selectedAttribute.safeZone[selectedSide] || {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttribute?._id, selectedSide])

    return background
}

