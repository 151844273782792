import React from 'react'
import {actionSetCampaignDetail} from '../../../context/SelectedVariantContext/action'
import ProductFacebookPixel from '../../../../../shared/FacebookPixel'
import FacebookInput from '../../../../../shared/FacebookInput'

const CampaignDisplayFacebookPixel = ({collections, tags, dispatch, pixelID, error, facebookConversion}) => {
    const collectionIDs = Array.isArray(collections) ? collections.map((collection) => collection.value) : []

    const _handleChangeInput = (e) => {
        const {
            target: {name, value},
        } = e
        dispatch(actionSetCampaignDetail({field: name, value: value.trim().replace(/\s/g, '')}))
    }
    return (
        <div className="Tracking Section">
            <ProductFacebookPixel
                collectionIDs={collectionIDs}
                tags={tags}
                faceBookPixelInput={
                    <FacebookInput
                        onChange={_handleChangeInput}
                        name="facebook_pixel_id"
                        value={pixelID}
                        // error={''}
                        editable={true}
                        textHeading="Facebook Pixel ID"
                    />
                }
                faceBookConversionInput={
                    <FacebookInput
                        onChange={_handleChangeInput}
                        name="facebook_conversion"
                        value={facebookConversion}
                        // error={''}
                        editable={true}
                        textHeading="Facebook Conversion API"
                    />
                }
            />
        </div>
    )
}

export default React.memo(CampaignDisplayFacebookPixel)

