import React from 'react'
import FeatureImage from '../../../shared/FeatureImage'

const VariantImages = ({mockup, parentIndex, numberImageDisplay, countImages}) => {
    if (mockup.total === undefined) return null
    const items = []

    for (let i = 0; i < Math.min(mockup.total, numberImageDisplay); i++) {
        items.push(
            <div key={`parent_${parentIndex}_image_${i}`} className='Image'>
                <FeatureImage className='d-block' src={mockup.images[i]} width={48}/>

                {
                    countImages && (i === numberImageDisplay - 1 && mockup.total > 2) &&
                    <div className="Rest">
                        +{mockup.total - 2}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className='VariantImages'>
            {items}
        </div>
    )
}

export default VariantImages
