import {useMemo} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'

function useEditorDesign() {
    const {
        state: {selectedAttribute, designs},
    } = useSelectedVariantContext()

    const design = useMemo(() => {
        if (!selectedAttribute?._id) return null

        return designs.find((item) => item.linkedAttrIds.includes(selectedAttribute._id)) || null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designs, selectedAttribute?._id])

    return design
}

export default useEditorDesign

