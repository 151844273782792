import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Image} from 'react-konva'

const BackgroundLayer = ({imageProps, onClick, showImage = false, isBackground = false, zIndexDefault}) => {
    const imageRef = useRef()
    const [image, setImage] = useState(null)

    useEffect(() => {
        if (imageRef.current && typeof zIndexDefault === 'number') imageRef.current.zIndex(zIndexDefault)
    }, [zIndexDefault])

    useEffect(() => {
        if (!showImage) return
        const img = new window.Image()
        img.onload = () => {
            setImage(img)
        }
        img.crossOrigin = 'Anonymous'
        img.src = imageProps.src

        return () => {
            img.onload = null
        }
    }, [showImage, imageProps.src])

    const imageData = useMemo(() => {
        if (!showImage) return imageProps

        return {
            ...imageProps,
            x: imageProps.x - 4,
            y: imageProps.y - 4,
            width: imageProps.width + 8,
            height: imageProps.height + 8,
        }
    }, [imageProps, showImage])

    return (
        <Image
            listening={false}
            ref={imageRef}
            {...imageData}
            image={showImage ? image : null}
            name={`${isBackground ? 'background' : 'fill'}`}
            fill={isBackground ? '#ffffff' : showImage ? '' : imageData.fill}
            onClick={onClick}
            globalCompositeOperation={!isBackground && !showImage ? 'source-atop' : ''}
        />
    )
}

export default BackgroundLayer

