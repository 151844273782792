import React, {Fragment, memo, useCallback, useEffect, useRef, useState} from 'react'
import {Group, Rect, Transformer} from 'react-konva'
import {updateToolbarStyle} from '../../context/SelectedVariantContext/helper'
import SpinnerLayer from './SpinnerLayer'
import {actionUpdateImageLayerLoading} from '../../context/SelectedVariantContext/action'
import {PATTERN_TYPE} from '../../../../constant'
import {toaster} from '../../../../shared/PFToast'

const ImageLayer = memo((props) => {
    const {imageProps, isSelected, onSelect, onChange, zIndexDefault, onChangeSizeImage, background, dispatch} = props
    const {isLock, patternType} = imageProps

    const imageRef = useRef(null)
    const trRef = useRef(null)
    const groupRef = useRef(null)

    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [grid, setGrid] = useState([])

    useEffect(() => {
        setLoading(true)
        dispatch(actionUpdateImageLayerLoading(true))

        const img = new window.Image()
        img.crossOrigin = 'Anonymous'

        img.onload = () => {
            const timer = setTimeout(() => {
                setImage(img)
                setLoading(false)
                dispatch(actionUpdateImageLayerLoading(false))
            }, 100)
            return () => {
                clearTimeout(timer)
            }
        }
        img.onerror = (e) => {
            setLoading(false)
            toaster({type: 'error', message: 'Error loading image' + e, duration: 6000})
        }

        img.src = imageProps.src
    }, [imageProps.src, dispatch])

    const getAdjustedSize = useCallback(
        (scaleFactor = 1) => {
            const {width: imageWidth, height: imageHeight, scaleX, scaleY} = imageProps
            if (scaleX && scaleY) {
                const tempScaleX = scaleX < 0 ? -scaleX : scaleX
                const tempScaleY = scaleY < 0 ? -scaleY : scaleY

                scaleFactor = Math.min(tempScaleX, tempScaleY) || 1
            }

            const adjustedWidth = imageWidth * scaleFactor
            const adjustedHeight = imageHeight * scaleFactor
            const adjustedRows = Math.ceil(background.width / adjustedWidth)
            let adjustedCols = Math.ceil(background.height / adjustedHeight)
            if (adjustedCols % 2 === 0) {
                adjustedCols = adjustedCols + 1
            }
            return {adjustedRows, adjustedCols, adjustedWidth, adjustedHeight}
        },
        [imageProps, background],
    )

    const renderGrid = useCallback(
        (rows = 2, cols = 2) => {
            let matrix = []
            let scaleFactor = 1
            const {
                x: imageX,
                y: imageY,
                hasPattern,
                patternType,
                horizontalSpacing = 0,
                verticalSpacing = 0,
            } = imageProps

            if (hasPattern) {
                const {adjustedRows, adjustedCols, adjustedWidth, adjustedHeight} = getAdjustedSize(scaleFactor)
                if (patternType === PATTERN_TYPE.HORIZONTAL) {
                    for (let i = -adjustedRows; i <= adjustedRows; i++) {
                        let x = imageX - i * (adjustedWidth + Number(horizontalSpacing) / background.ratioDefault)
                        if (x === 0) continue
                        matrix.push({
                            ...imageProps,
                            y: imageY,
                            x,
                        })
                    }
                } else if (patternType === PATTERN_TYPE.VERTICAL) {
                    for (let i = -adjustedCols; i <= adjustedCols; i++) {
                        let y = imageY - i * (adjustedHeight + Number(verticalSpacing) / background.ratioDefault)
                        if (y === 0) continue
                        matrix.push({
                            ...imageProps,
                            fillPatternImage: image,
                            x: imageX,
                            y: y,
                        })
                    }
                } else {
                    for (let i = -adjustedRows; i <= adjustedRows; i++) {
                        for (let j = -adjustedCols; j < adjustedCols; j++) {
                            if (i === 0 && j === 0) continue
                            let x = imageX - i * (adjustedWidth + Number(horizontalSpacing) / background.ratioDefault)
                            let y = imageY - j * (adjustedHeight + Number(verticalSpacing) / background.ratioDefault)
                            if (patternType === PATTERN_TYPE.HORIZONTAL_BRICK) {
                                x += j % 2 === 0 ? 0 : adjustedWidth / 2
                            }
                            if (patternType === PATTERN_TYPE.VERTICAL_BRICK) {
                                y += i % 2 === 0 ? 0 : adjustedHeight / 2
                            }

                            matrix.push({
                                ...imageProps,
                                fillPatternImage: image,
                                x: x,
                                y: y,
                                name: `rect_${i}_${j}`,
                                key: `rect_${i}_${j}`,
                                mainElement: i === 0 && j === 0,
                            })
                        }
                    }
                }
            } else {
                matrix = []
            }
            return matrix
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [imageProps, background, imageProps.hasPattern],
    )

    useEffect(() => {
        setGrid(renderGrid())
    }, [renderGrid])

    useEffect(() => {
        const imageNode = imageRef.current
        if (imageNode) {
            imageNode.on('mouseenter mouseup dragend', () => {
                imageNode.getStage().container().style.cursor = 'grab'
            })
            imageNode.on('mouseleave', () => {
                imageNode.getStage().container().style.cursor = 'default'
            })
            imageNode.on('mousedown', () => {
                imageNode.getStage().container().style.cursor = 'grabbing'
            })

            return () => {
                imageNode.off('mouseenter')
                imageNode.off('mouseup')
                imageNode.off('dragend')
                imageNode.off('mouseleave')
                imageNode.off('mousedown')
            }
        }
    }, [loading])

    const onShowToolbar = useCallback(() => {
        imageProps.rect = imageRef.current?.getClientRect()
        const trNode = trRef.current
        if (trNode && imageProps) {
            const rotateIcon = trNode.findOne('.rotater')
            const pos = rotateIcon.getAbsolutePosition()
            const {rotation} = imageProps
            const rect = imageRef.current?.getClientRect()
            updateToolbarStyle({...pos, height: rect.height / 2, rotation}, 'image-toolbar')
        }
    }, [imageProps])

    useEffect(() => {
        const imageNode = imageRef.current
        const trNode = trRef.current
        if (imageNode && trNode) {
            if (isSelected) {
                trNode.moveToTop()
                trNode.nodes([imageNode])
                !isLock && onShowToolbar()
            } else {
                imageNode.zIndex(zIndexDefault)
                onHideToolbar()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelected, isLock, zIndexDefault, loading])

    const onItemClick = () => {
        const imageNode = imageRef.current
        if (!imageNode) return

        onSelect({...imageProps})
        setTimeout(() => {
            onShowToolbar()
        }, 100)
    }

    const onHideToolbar = () => {
        const toolbar = document.getElementById('image-toolbar')
        toolbar.style.display = 'none'
    }

    const onDragEnd = (event) => {
        if (!event.target) return

        const attrs = event.target.attrs

        onChange(attrs)
        onShowToolbar()
    }

    const onTransformEnd = (event) => {
        if (!event.target) return
        const imageAttrs = event.target.attrs
        console.log('event.target', event.target)

        onChange(imageAttrs)
        onShowToolbar()
    }

    const onTransformRealTime = (event) => {
        const imageNode = event.target
        if (!imageNode) return
        const imageAttrs = imageNode.attrs
        if (imageAttrs.dpi === Infinity) return

        const size = {
            width: imageNode.width() || 1,
            height: imageNode.height() || 1,
            scaleX: imageNode.scaleX() || 1,
            scaleY: imageNode.scaleY() || 1,
        }
        onHideToolbar()
        onChange(imageAttrs)
        onChangeSizeImage(size)
    }


    const boundBoxFunc = (oldBox, newBox) => {
        const minSize = Math.min(background.width, background.height) / 100
        if (newBox.width < minSize || newBox.height < minSize) {
            return oldBox
        }
        return newBox
    }

    const handleTransform = (event) => {
        const anchor = event.target.attrs
        const group = event.target.parent
        const children = group.getChildren()

        children.forEach((node, index) => {
            node.scaleX(anchor.scaleX)
            node.scaleY(anchor.scaleY)
            node.rotation(anchor.rotation)
        })
    }

    const handleDragMoveGroup = (event) => {
        if (!imageProps.hasPattern) return
        onShowToolbar()
    }

    return (
        <Fragment>
            {loading ? (
                <SpinnerLayer {...imageProps} />
            ) : (
                <Fragment>
                    <Group
                        onClick={() => onSelect({...imageProps})}
                        draggable
                        ref={groupRef}
                        onDragMove={handleDragMoveGroup}
                        dragBoundFunc={(pos) => {
                            const group = groupRef.current
                            const background = group.getParent().children[0] // Assuming the background is the first child of the group's parent
                            const mainElement = imageRef.current

                            const {width: bgWidth, height: bgHeight} = background.getClientRect()

                            // Define your boundaries based on the background size and main element size
                            const minX = background.getAbsolutePosition().x - mainElement.getClientRect().width / 2
                            const maxX = minX + bgWidth + mainElement.getClientRect().width
                            const minY = background.getAbsolutePosition().y - mainElement.getClientRect().height / 2
                            const maxY = minY + bgHeight + mainElement.getClientRect().height

                            // Calculate the new position of the main element after the drag event
                            const mainX = pos.x + mainElement.getAbsolutePosition().x - group.getAbsolutePosition().x
                            const mainY = pos.y + mainElement.getAbsolutePosition().y - group.getAbsolutePosition().y

                            // Check if the new position of the main element is within the boundaries
                            const isWithinBoundaries = mainX >= minX && mainX <= maxX && mainY >= minY && mainY <= maxY

                            // If it's not within the boundaries, adjust the group position to the nearest valid position within the boundaries
                            if (!isWithinBoundaries) {
                                const posX = Math.max(
                                    minX - mainElement.getAbsolutePosition().x + group.getAbsolutePosition().x,
                                    Math.min(
                                        maxX - mainElement.getAbsolutePosition().x + group.getAbsolutePosition().x,
                                        pos.x,
                                    ),
                                )
                                const posY = Math.max(
                                    minY - mainElement.getAbsolutePosition().y + group.getAbsolutePosition().y,
                                    Math.min(
                                        maxY - mainElement.getAbsolutePosition().y + group.getAbsolutePosition().y,
                                        pos.y,
                                    ),
                                )

                                return {x: posX, y: posY}
                            }

                            // If it's within the boundaries, return the new position
                            return pos
                        }}
                    >
                        {grid.map((item, index) => (
                            <Rect
                                {...item}
                                // visible={false}
                                draggable={false}
                                fillPatternImage={image}
                                globalCompositeOperation="source-atop"
                            />
                        ))}
                        <Rect
                            {...imageProps}
                            ref={imageRef}
                            draggable={!isLock && !imageProps.hasPattern}
                            listening={true}
                            offsetX={
                                patternType === PATTERN_TYPE.HORIZONTAL ? imageProps.width / 2 : imageProps.offsetX
                            }
                            offsetY={patternType === PATTERN_TYPE.VERTICAL ? imageProps.height / 2 : imageProps.offsetY}
                            fillPatternImage={image}
                            globalCompositeOperation="source-atop"
                            onClick={onItemClick}
                            onTap={onItemClick}
                            onDragStart={onItemClick}
                            onDragEnd={onDragEnd}
                            onTransformEnd={onTransformEnd}
                            onTransform={handleTransform}
                            name={'main'}
                            dragBoundFunc={(pos) => {
                                const group = groupRef.current
                                const background = group.getParent().children[0] // Assuming the background is the first child of the group's parent
                                const mainElement = imageRef.current.getClientRect()

                                const {width: bgWidth, height: bgHeight} = background.getClientRect()
                                const minX = background.getAbsolutePosition().x - mainElement.width / 2
                                const maxX = minX + bgWidth + mainElement.width
                                const minY = background.getAbsolutePosition().y - mainElement.height / 2
                                const maxY = minY + bgHeight + mainElement.height

                                return {
                                    x: Math.min(Math.max(pos.x, minX), maxX),
                                    y: Math.min(Math.max(pos.y, minY), maxY),
                                }
                            }}
                        />
                    </Group>
                </Fragment>
            )}
            {isSelected && !loading && (
                <Transformer
                    ref={trRef}
                    // keepRatio={true}
                    // centeredScaling={true}
                    anchorCornerRadius={30}
                    rotateAnchorOffset={20}
                    enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                    boundBoxFunc={boundBoxFunc}
                    onTransform={onTransformRealTime}
                    rotateAnchorCursor="grabbing"
                />
            )}
        </Fragment>
    )
})

export default ImageLayer
