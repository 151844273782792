import React, {Fragment} from 'react'
import {CompactPicker} from 'react-color'
import {Button, ButtonGroup, UncontrolledPopover, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as DuplicateAll} from '../../../assets/icons/all-layers.svg'
import {ReactComponent as FitToWidth} from '../../../assets/icons/arrow-h.svg'
import {ReactComponent as FitToHeight} from '../../../assets/icons/arrow-v.svg'
import {ReactComponent as Duplicate} from '../../../assets/icons/duplicate.svg'
import {ReactComponent as HorizontalFlip} from '../../../assets/icons/horizontal-flip.svg'
import {ReactComponent as Trash} from '../../../assets/icons/trash-alt.svg'
import {ReactComponent as VerticalFlip} from '../../../assets/icons/vertical-flip.svg'
import {useEditorBackground} from '../../../hooks/useEditorBackground'
// import {useEditorLayers} from '../../../hooks/useEditorLayers'
// import {toaster} from '../../../shared/PFToast'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {actionDuplicateLayer, removeLayer, updateLayerAttribute} from '../context/SelectedVariantContext/action'
import {CMYK_COLORS} from '../context/SelectedVariantContext/constants'
// import {checkImageUploadLimit, turnCmykToHex, turnHexToCmyk} from '../context/SelectedVariantContext/helper'
import {turnCmykToHex, turnHexToCmyk} from '../context/SelectedVariantContext/helper'
import classNames from 'classnames'

const MockupEditorToolbar = () => {
    const {
        dispatch,
        state: {selectedLayer},
    } = useSelectedVariantContext()

    // const layers = useEditorLayers()
    const background = useEditorBackground()

    const onChangeTextAlign = (align) => {
        if (!selectedLayer || selectedLayer?.isLock) return
        selectedLayer.align = align
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const onChangeTextColor = (color) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        let selectedColor = '#000000'
        if (color.hex !== '#000000') {
            // convert hex từ color picker sang cmyk để giảm mã màu
            const hexTocmyk = turnHexToCmyk(color.hex)

            // sau đó đổi lại từ cmyk sang hex để set vào layer (vì layer không nhận mã màu cmyk)
            selectedColor = turnCmykToHex(hexTocmyk)
        }
        selectedLayer.fill = selectedColor
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const onFlip = (flipAxis) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                scaleX: flipAxis === 'x' ? -1 * selectedLayer.scaleX : selectedLayer.scaleX,
                scaleY: flipAxis === 'y' ? -1 * selectedLayer.scaleY : selectedLayer.scaleY,
            })
        )
    }

    const onFullAxis = (axis) => {
        if (!selectedLayer || selectedLayer.isLock) return

        const {x: bgX, y: bgY, width: bgWidth, height: bgHeight} = background
        const {width: selectedWidth, height: selectedHeight, scaleX, scaleY} = selectedLayer

        const newAttribute = {...selectedLayer}
        const scaleBg = axis === 'x' ? bgWidth / selectedWidth : bgHeight / selectedHeight

        if (axis === 'x') {
            newAttribute.x = bgX + bgWidth / 2
            newAttribute.y = bgY + bgHeight / 2
        } else {
            newAttribute.x = bgX + bgWidth / 2
            newAttribute.y = bgY + bgHeight / 2
        }

        newAttribute.scaleX = scaleX < 0 ? scaleBg * -1 : scaleBg
        newAttribute.scaleY = scaleY < 0 ? scaleBg * -1 : scaleBg

        dispatch(updateLayerAttribute(newAttribute))
    }

    /**
     * Handle duplicate layer for current stage side
     */
    const handleDuplicate = () => {
        if (!selectedLayer) return
        // if (selectedLayer.layerType === 'image') {
        //     const exceedsLimit = checkImageUploadLimit(layers)
        //     if (exceedsLimit)
        //         return toaster({
        //             type: 'error',
        //             message: '5 images are allowed to upload',
        //         })
        // }
        dispatch(actionDuplicateLayer(selectedLayer))
    }

    const handleDuplicateAllArea = () => {
        if (!selectedLayer) return
        dispatch(actionDuplicateLayer(selectedLayer, {scopeAll: true}))
    }

    const handleRemoveLayer = () => {
        if (!selectedLayer || selectedLayer?.isLock) return

        dispatch(removeLayer(selectedLayer))
    }

    return (
        <Fragment>
            <div className="MockupEditorToolbar ImageToolbar" id="image-toolbar">
                <Button className="mx-1" color="default" onClick={() => onFlip('x')} id="flipX">
                    <HorizontalFlip width={18} height={18} />
                </Button>
                <Button className="mx-1" color="default" onClick={() => onFlip('y')} id="flipY">
                    <VerticalFlip width={18} height={18} />
                </Button>
                <Button className="mx-1" color="default" id="fitHeight" onClick={() => onFullAxis('y')}>
                    <FitToHeight width={18} height={18} />
                </Button>
                <Button className="mx-1" color="default" id="fitWidth" onClick={() => onFullAxis('x')}>
                    <FitToWidth width={18} height={18} />
                </Button>
                <CommonTool
                    type="image"
                    handleDuplicate={handleDuplicate}
                    handleDuplicateAllArea={handleDuplicateAllArea}
                    handleRemoveLayer={handleRemoveLayer}
                />
            </div>
            <div className="MockupEditorToolbar TextToolbar" id="text-toolbar">
                <ButtonGroup className="mx-1 my-2">
                    <Button
                        outline
                        id="alignLeft"
                        className={classNames({active: selectedLayer && selectedLayer.align === 'left'})}
                        onClick={() => onChangeTextAlign('left')}
                    >
                        <i className="fa fa-align-left"></i>
                    </Button>
                    <Button
                        outline
                        id="alignCenter"
                        className={classNames({active: selectedLayer && selectedLayer.align === 'center'})}
                        onClick={() => onChangeTextAlign('center')}
                    >
                        <i className="fa fa-align-center"></i>
                    </Button>
                    <Button
                        outline
                        id="alignRight"
                        className={classNames({active: selectedLayer && selectedLayer.align === 'right'})}
                        onClick={() => onChangeTextAlign('right')}
                    >
                        <i className="fa fa-align-right"></i>
                    </Button>
                </ButtonGroup>
                <Button className="mx-1" color="default" id="TextColorSelector">
                    <i className="fa fa-font"></i>
                    <div className="TextColor" style={{backgroundColor: `black`}}></div>
                </Button>
                <CommonTool
                    type="text"
                    handleDuplicate={handleDuplicate}
                    handleDuplicateAllArea={handleDuplicateAllArea}
                    handleRemoveLayer={handleRemoveLayer}
                />
                <UncontrolledPopover trigger="legacy" placement="top" target="TextColorSelector">
                    <CompactPicker
                        color={selectedLayer?.fill || '#ffffff'}
                        colors={CMYK_COLORS}
                        onChange={onChangeTextColor}
                    />
                </UncontrolledPopover>
            </div>
            <ButtonTooltip />
        </Fragment>
    )
}

const CommonTool = (props) => {
    const {type, handleDuplicate, handleDuplicateAllArea, handleRemoveLayer} = props
    return (
        <Fragment>
            <Button className="mx-1" id={`${type}_duplicateAll`} color="default" onClick={handleDuplicateAllArea}>
                <DuplicateAll width={18} height={18} />
            </Button>
            <Button className="mx-1" id={`${type}_duplicate`} color="default" onClick={handleDuplicate}>
                <Duplicate width={18} height={18} />
            </Button>
            <Button className="mx-1" id={`${type}_remove`} color="default" onClick={handleRemoveLayer}>
                <Trash width={18} height={18} />
            </Button>
        </Fragment>
    )
}

const ButtonTooltip = () => {
    return (
        <Fragment>
            <UncontrolledTooltip placement="top" target="flipX">
                Horizontal flip
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="flipY">
                Vertical flip
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="fitHeight">
                Fit to height
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="fitWidth">
                Fit to width
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="TextColorSelector">
                Change text color
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="alignLeft">
                Align left
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="alignCenter">
                Align center
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="alignRight">
                Align right
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="image_duplicateAll">
                Duplicate all sides
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="image_duplicate">
                Duplicate
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="image_remove">
                Remove
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="text_duplicateAll">
                Duplicate all sides
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="text_duplicate">
                Duplicate
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="text_remove">
                Remove
            </UncontrolledTooltip>
        </Fragment>
    )
}

export default MockupEditorToolbar

