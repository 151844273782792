import Bluebird from 'bluebird'
import {useState} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {
    actionAddImageLayer,
    actionSetImageCompressProgress,
    actionSetRunningImageCompress,
} from '../components/step2/context/SelectedVariantContext/action'
import {handleUploadFile, handleUploadImageByLink} from '../components/step2/context/SelectedVariantContext/helper'
import {getLinksUploadArtwork, uploadDesignLibrary, uploadTempArtwork} from '../services/ArtworkTemplateServices'
import {toaster} from '../shared/PFToast'
import { isBackofficeEnv } from '../helpers/isBackoffceEnv'
import { getLocalData, setLocalData } from '../services/StorageServices'

const PREFIX_LOG = '[mockup-generator]'
const LIMIT_IMAGE = 5

const useUploadFile = () => {
    const {dispatch} = useSelectedVariantContext()

    const [pending, setPending] = useState(false)

    const _handleProgress = (progress) => {
        dispatch(actionSetImageCompressProgress(progress))
    }

    const _randomPercent = (max, min) => {
        return Math.floor(Math.random() * (max - min) + min)
    }

    const _fetchUploadLink = async () => {
        try {
            const {success, data} = await getLinksUploadArtwork({limit: 1})
            if (!success) {
                return
            }
            return data[0]
        } catch (error) {}
    }

    const uploadFile = async (file) => {
        console.time(`${PREFIX_LOG}[upload image]`)
        _handleProgress(0)
        setPending(true)
        try {
            if (1 === LIMIT_IMAGE) {
                toaster({
                    type: 'error',
                    message: `${LIMIT_IMAGE} images are allowed to upload`,
                })
                return
            }
            await Bluebird.delay(200)
            _handleProgress(_randomPercent(30, 10))
            await Bluebird.delay(200)
            _handleProgress(_randomPercent(60, 35))
            await Bluebird.delay(200)
            _handleProgress(_randomPercent(95, 75))
            let imageObj = null
            const imageUrl = await _fetchUploadLink()
            const link = imageUrl.split('?')[0]
            await uploadTempArtwork(imageUrl, file, '')
            if (isBackofficeEnv()) {
                const payload = {
                    link,
                    name: file.name,
                }
                const [resImageObj] = await Promise.all([
                    handleUploadFile(file),
                    uploadDesignLibrary(payload),
                ])
                imageObj = resImageObj
            } else {
                const designLibrary = getLocalData('designLibrary') || []
                imageObj = await handleUploadImageByLink(link, file.name)
                designLibrary.push(imageObj)
                setLocalData('designLibrary', designLibrary)
            }
            dispatch(actionAddImageLayer(imageObj))
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message || 'An error occurred while reading the image',
            })
        } finally {
            setPending(false)
            _handleProgress(100)
            setTimeout(() => {
                dispatch(actionSetRunningImageCompress(false))
            }, 500)
            console.timeEnd(`${PREFIX_LOG}[upload image]`)
        }
    }

    return {uploadFile, pending}
}

export default useUploadFile

