const _onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
}

/**
 * @param array
 * @return Array
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (array) => {
    return !Array.isArray(array) ? [] : array.filter(_onlyUnique)
}
