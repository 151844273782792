import {createStoreAPI} from './CreateStoreAPIService'

export const uploadArtworkCaptureV2 = (payload) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return process.env.REACT_APP_NEXT_MOCKUP_GENERATOR_SERVICE
        },
        timeout: 600000,
    })
    return api.makeAuthRequest({
        url: '/v2/artworks/capture',
        method: 'POST',
        data: payload,
    })
}

export const generatePersonalizedArtworkV4 = (formData) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return process.env.REACT_APP_NEXT_MOCKUP_GENERATOR_SERVICE
        },
        timeout: 600000,
    })
    return api.makeAuthRequest({
        url: '/v4/artwork-new-campaign',
        method: 'POST',
        data: formData,
    })
}

