import React from 'react'
import {Input} from 'reactstrap'
import {actionSetCampaignDetail} from '../../../context/SelectedVariantContext/action'

const CampaignDisplay = ({isFeatured, isStealthy, isShopHidden, isCollectionHidden, dispatch}) => {
    const _handleChangeInput = (name, value) => {
        dispatch(actionSetCampaignDetail({field: name, value}))
    }

    return (
        <div className="CampaignDisplay Section">
            <div className="Heading">Display</div>

            <div className="custom-control custom-checkbox">
                <Input
                    type="checkbox"
                    className="custom-control-input"
                    id="inputHideFromSearchEngines"
                    name="is_stealthy"
                    checked={isStealthy}
                    onChange={(e) => _handleChangeInput('is_stealthy', e.target.checked)}
                />
                <label className="custom-control-label ms-2" htmlFor="inputHideFromSearchEngines">
                    Hide from search engines
                </label>
            </div>

            <div className="custom-control custom-checkbox mt-2">
                <Input
                    type="checkbox"
                    className="custom-control-input"
                    id="inputAddToHomePage"
                    name="is_featured"
                    checked={isFeatured}
                    onChange={(e) => _handleChangeInput('is_featured', e.target.checked)}
                />
                <label className="custom-control-label ms-2" htmlFor="inputAddToHomePage">
                    Add to homepage
                </label>
            </div>

            <div className="custom-control custom-checkbox mt-2">
                <Input
                    type="checkbox"
                    className="custom-control-input"
                    id="inputHideFormListingPage"
                    name="hide_from_listing_pages"
                    checked={isShopHidden && isCollectionHidden}
                    onChange={(e) => _handleChangeInput('hide_from_listing_pages', e.target.checked)}
                />
                <label className="custom-control-label ms-2" htmlFor="inputHideFormListingPage">
                    Hide from listing pages
                </label>
            </div>
        </div>
    )
}

export default React.memo(CampaignDisplay)

