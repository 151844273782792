import React from 'react'
import PFTagInput from '../../../../../shared/TagInput'
import { actionSetCampaignDetail } from '../../../context/SelectedVariantContext/action'

const CampaignTags = ({tags, dispatch}) => {
    const _handleChange = (tags) => {
        dispatch(actionSetCampaignDetail({field: 'tags', value: tags}))
    }

    return (
        <div className="CampaignTags Section">
            <div className="Heading">Tags</div>

            <PFTagInput inputId="inputProductTag" onChange={_handleChange} defaultTags={tags} />
        </div>
    )
}

export default React.memo(CampaignTags)

