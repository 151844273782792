import classNames from 'classnames'
import React from 'react'
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg'
import {useSelectedVariantContext} from './context/SelectedVariantContext'
import {actionChangeDisplay, actionChangeSelectedLayer} from './context/SelectedVariantContext/action'
import {toaster} from '../../shared/PFToast'
import { isBackofficeEnv } from '../../helpers/isBackoffceEnv'

const NavBar = () => {

    const {
        state: {display, pickerAttributes, runningImageCompress, productDetail},
        dispatch,
    } = useSelectedVariantContext()

    const toggleDisplay = (_display) => {
        if (_display !== display) {
            dispatch(actionChangeDisplay(_display))
        }
    }

    const switchPreview = () => {
        let invalidDpi = null
        for (const attr of pickerAttributes) {
            const {safeZone} = attr

            for (const [key, value] of Object.entries(safeZone)) {
                const totalInvalidDpi = value.invalidsDpi.length
                if (totalInvalidDpi) {
                    invalidDpi = {
                        side: key,
                        total: totalInvalidDpi,
                    }
                    break
                }
            }
        }
        if (invalidDpi) {
            toaster({
                type: 'error',
                message: `Side ${invalidDpi.side} has ${invalidDpi.total} layer image invalid DPI`,
            })
        } else {
            dispatch(actionChangeSelectedLayer(null))
            toggleDisplay('preview')
        }
    }

    const backToProductList = () => {
        const confirmed = window.confirm('Changes you made may not be saved.')
        if (confirmed) {
            if (isBackofficeEnv()) {
                window.open(`${process.env.REACT_APP_BASE_URL}/a/products/mockup-generator/variants`, '_self')
            } else {
                window.open(`${process.env.REACT_APP_WEBSITE_URL}/product/${productDetail.slug}`, '_self')
            }
        }
    }

    return (
        <div className="NavBar2 no-gutters">
            <div className="Left col-3 text-left">
                {display === 'pricing' ? (
                    <span className="PrevStep cursor-pointer" onClick={() => toggleDisplay('design')}>
                        <span className="Icon">
                            <ChevronLeft width={12} height={14} />
                        </span>
                        Design
                    </span>
                ) : (
                    <span className="PrevStep cursor-pointer" onClick={backToProductList}>
                        <span className="Icon">
                            <ChevronLeft width={12} height={14} />
                        </span>
                        Products
                    </span>
                )}
            </div>

            <div className="Right col-3">
                {display !== 'pricing' && (
                    <div className="SwitchBetweenDesignAndPreview d-flex justify-content-end">
                        <button
                            type="button"
                            id="design_mockup_v2"
                            onClick={() => toggleDisplay('design')}
                            className={classNames('Design', {active: display === 'design'})}
                        >
                            Design
                        </button>

                        <button
                            type="button"
                            id="preview_mockup_v2"
                            onClick={switchPreview}
                            disabled={runningImageCompress}
                            className={classNames('Preview', {active: display === 'preview'})}
                        >
                            Preview
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default NavBar

