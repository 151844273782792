import {getLocalData, setLocalData} from './StorageServices'

const _store = {
    state: {
        path: getLocalData('path', ''),
        accessToken: getLocalData('accessToken', ''),
        refreshToken: getLocalData('refreshToken', ''),
    },
    subscribers: [],
}

export const setAccessToken = (accessToken) => {
    _store.state = {
        ..._store.state,
        accessToken,
    }

    setLocalData('accessToken', accessToken)
}

export const setRefreshToken = (refreshToken) => {
    _store.state = {
        ..._store.state,
        refreshToken,
    }

    setLocalData('refreshToken', refreshToken)
}

const _getStoreRoles = () => {
    const scopes = getLocalData('storeRoles', [])

    return Array.isArray(scopes) ? scopes : []
}

export const getAccessToken = () => getLocalData('accessToken') || _store.state.accessToken || ''

export const getRefreshToken = () => getLocalData('refreshToken') || _store.state.refreshToken || ''

export const getEnvironment = () => getLocalData('path') || _store.state.path || ''

export const hasRole = (role = '') => {
    const roles = _getStoreRoles()

    return roles.indexOf(role) !== -1
}
