import React from 'react'
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import MockupGeneratorContainer from './components/step2/MockupGeneratorContainer'
import MockupGeneratorRedirect from './components/redirect'
import WelcomePageContainer from './components/welcome'

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<MockupGeneratorRedirect />} />
                    <Route path="/ping" element={<WelcomePageContainer />} />
                    <Route path="/:productId" element={<MockupGeneratorContainer />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App

