import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'reactstrap'

const PFButton = (props) => {
    const {id = '', buttonText, loading, color, className, style = {}, disabled, onClick, size} = props

    return (
        <Button id={id} color={color} className={className} size={size} style={style} disabled={disabled} onClick={onClick}>
            {loading && <i className="fas fa-circle-notch fa-spin me-2"></i>}
            {buttonText}
        </Button>
    )
}

PFButton.defaultProps = {
    buttonText: 'Confirm',
    color: 'default', // enum: [default, primary, secondary, warning, danger]
    loading: false,
    disabled: false,
}

PFButton.propTypes = {
    size: PropTypes.string,
    buttonText: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}

export default PFButton
