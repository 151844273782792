import {createStoreAPI} from './CreateStoreAPIService'

export const getProductCatalogById = (catalogId, slug) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return `${process.env.REACT_APP_BASE_URL || 'https://seller-staging.merchize.com'}/api/product-line`
        },
        storeId: slug,
    })

    return api.makeAuthRequest({
        url: `/catalog-products/${catalogId}`,
        method: 'GET',
    })
}

export const getWebsiteProductCatalogById = (catalogId) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return `${process.env.REACT_APP_BASE_URL}/api/product-line`
        },
    })
    return api.makeAuthRequest({
        url: `/website/catalog-products/${catalogId}`,
        method: 'GET',
    })
}

export const saveTempMockupDesign = (data) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return `${process.env.REACT_APP_BASE_URL}/api/product-line`
        },
    })
    return api.makeAuthRequest({
        url: `/website/products/temp`,
        method: 'POST',
        data,
    })
}

export const saveTempImagesDesign = (data) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return `${process.env.REACT_APP_BASE_URL}/api/product-line`
        },
    })
    return api.makeAuthRequest({
        url: `/website/design-images/temp`,
        method: 'POST',
        data,
    })
}
