import classNames from 'classnames'
import React, {Fragment, useEffect, useState} from 'react'
import {ReactComponent as Check} from '../../../assets/icons/check.svg'
import {ReactComponent as Time} from '../../../assets/icons/times.svg'
import {handleCalculateDpi} from '../context/SelectedVariantContext/helper'

const DpiImage = ({layer = {}, showDetail = true, classes = ''}) => {
    const [dpi, setDpi] = useState(null)

    useEffect(() => {
        if (layer && layer.layerType === 'image') {
            const dpi = handleCalculateDpi(layer)
            setDpi(dpi)
        }
    }, [layer])

    return (
        <Fragment>
            {dpi && (
                <div className={classNames('ImageDPI', classes, {invalid: dpi < layer.bgDpi})}>
                    {showDetail && (
                        <span className="Icon">
                            {dpi < layer.bgDpi ? <Time width={10} height={10} /> : <Check width={12} height={12} />}
                        </span>
                    )}
                    <span className="Text">{dpi === Infinity || dpi === 'Infinity' ? 'Vector' : `DPI ${dpi}`}</span>
                </div>
            )}
        </Fragment>
    )
}

export default DpiImage

