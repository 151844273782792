import React, { useEffect, useState } from 'react'
import VariantTableRow from './VariantTableRow'
import { Input } from 'reactstrap'

const VariantTable = (props) => {
    const {
        variants,
        attributes,
        checkedAll,
        setSelectedVariants,
        selectedVariants,
        filteredVariants,
        setFilteredVariants,
    } = props
    const [filter, setFilter] = useState(
        Object.assign({}, ...attributes.map((attribute) => ({[attribute.name]: attribute.values.map((v) => v.code)})))
    )

    useEffect(() => {
        setFilteredVariants(variants)
    }, [variants, setFilteredVariants])

    if (!variants.length) return null

    const _handleSelectAll = (event) => {
        const {checked} = event.target
        if (checked) {
            const allVariants = variants.filter((v) => !v.disabled).map((v) => v._id)
            setSelectedVariants(allVariants)
        } else {
            setSelectedVariants([])
        }
    }

    const _filterVariants = (variants, filter) => {
        const filteredVariants = variants.filter((item) => {
            const attributes = item.attributes
            // Check if all the filter conditions match the item attributes
            return Object.entries(filter).every(([key, values]) => {
                if (!values.length) return false
                const attribute = attributes.find((attr) => attr.name === key)
                return attribute && values.includes(attribute.value_code)
            })
        })
        return filteredVariants
    }

    const _handleFilterVariants = async (filter) => {
        setFilter(filter)
        // const allValuesEmpty = Object.values(filter).every((value) => Array.isArray(value) && value.length === 0)
        // if (allValuesEmpty) {
        //     return setFilteredVariants(variants)
        // }
        const filtered = _filterVariants(variants, filter)
        if (filtered.length === 0 || filtered.some((v) => !selectedVariants.includes(v._id))) {
            //setSelectedVariants([])
        }
        if (selectedVariants.length > filtered.length) {
            //setSelectedVariants(filtered.map((v) => v._id))
        }
        setFilteredVariants(filtered)
    }

    return (
        <div className="VariantTable">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center table-col-5">
                                <div className="custom-control custom-checkbox">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="selectAll"
                                        checked={checkedAll}
                                        onChange={_handleSelectAll}
                                    />
                                    <label className="custom-control-label" htmlFor="selectAll"></label>
                                </div>
                            </th>
                            {!!attributes.length &&
                                attributes.map((attribute, index) => {
                                    return (
                                        <th key={`attribute_thead_${index}`} className="text-capitalize">
                                            {attribute.name}
                                        </th>
                                    )
                                })}
                            <th className="text-right table-col-15">Base Cost</th>
                        </tr>
                    </thead>
                    <VariantTableRow
                        filter={filter}
                        selectedVariants={selectedVariants}
                        filteredVariants={filteredVariants}
                        handleFilterVariants={_handleFilterVariants}
                        {...props}
                    />
                </table>
            </div>
        </div>
    )
}

export default VariantTable

