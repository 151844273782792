import React from 'react'
import {Editor} from '@tinymce/tinymce-react'
import classnames from 'classnames'
import {actionSetCampaignDetail} from '../../../context/SelectedVariantContext/action'
import {APIKeyTinymceEditor, TinyMceEditorPlugins, TinyMceEditorToolbar} from '../../../../../constant'

const CampaignDescription = ({isBackofficeEnv, description, dispatch, error}) => {
    const _changeEditorState = (value) => {
        dispatch(actionSetCampaignDetail({field: 'description', value}))
    }

    return (
        <div className={classnames('CampaignDescription Section', {invalid: error})}>
            <div className="Heading">Description</div>
            <Editor
                value={description}
                apiKey={APIKeyTinymceEditor}
                init={{
                    height: `${isBackofficeEnv ? 480 : 650}`,
                    skin: 'small',
                    isDisabled: true,
                    icons: 'small',
                    plugins: TinyMceEditorPlugins,
                    toolbar: TinyMceEditorToolbar,
                }}
                onEditorChange={(value) => _changeEditorState(value)}
            />
        </div>
    )
}

export default React.memo(CampaignDescription)

