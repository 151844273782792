import createAPIServices from './CreateApiServices'
import {getAccessToken} from './AuthServices'
import {getLocalData} from './StorageServices'
import {getStoreSetting} from './CurrentStoreServices'

const _getDefaultBaseURL = (suffix = '') => {
    let prefixURL = `${process.env.REACT_APP_BASE_URL}/api/stores`
    const currentStore = getLocalData('storeId')
    const backofficeUrl = getStoreSetting('backoffice_url', null)

    if (backofficeUrl) {
        return backofficeUrl + suffix
    }

    return `${prefixURL}/${currentStore}${suffix}`
}

const _create = (options = {}) => {
    const {suffix, getBaseURL, adminUrl, timeout, onUploadProgress, isCallAdmin, storeId} = Object.assign({}, options)

    const currentStore = getLocalData('storeId') || storeId

    const _getBaseURLByStore = () => {
        return typeof getBaseURL === 'function' ? getBaseURL(currentStore, getStoreSetting) : _getDefaultBaseURL(suffix)
    }

    const _getBaseHeaders = () => {
        const token = getAccessToken()

        const headers = {}

        if (currentStore) {
            headers['X-Store-ID'] = currentStore
        }

        if (token) {
            headers['X-Store-Token'] = token
        }

        return headers
    }

    const baseURL = _getBaseURLByStore()
    const headers = _getBaseHeaders()
    const _api = createAPIServices({
        baseUrl: isCallAdmin ? adminUrl : baseURL,
        headers,
        timeout,
        onUploadProgress,
    })

    return _api
}

const _validateOptions = (options) => {
    const {suffix, getBaseURL, adminUrl, timeout, onUploadProgress, isCallAdmin = false} = Object.assign({}, options)

    return {
        suffix: suffix || '',
        getBaseURL,
        adminUrl,
        isCallAdmin,
        timeout: timeout || 30000,
        onUploadProgress,
    }
}

export const createStoreAPI = (options = {}) => {
    const opts = _validateOptions(options)

    return _create(opts)
}

export const getDefaultBaseURL = _getDefaultBaseURL

