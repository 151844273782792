import React, {Fragment} from 'react'
import {Button} from 'reactstrap'
import ImageUpload from '../../../../assets/images/upload-artwork.png'
import {IMAGE_TYPES, MAX_IMAGE_UPLOAD_SIZE, MAX_SVG_UPLOAD_SIZE, SVG_TYPE} from '../../../../constant'
import useUploadFile from '../../../../hooks/useUploadFile'
import PFBoxUploader from '../../../../shared/PFBoxUploader'
import {toaster} from '../../../../shared/PFToast'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {actionSetRunningImageCompress} from '../../context/SelectedVariantContext/action'

const LIMIT_CAPACITY = 9000000000 // 9gb

const UploadByComputer = (props) => {
    const {toggle, accept, capacity = null} = props
    const {dispatch} = useSelectedVariantContext()
    const {uploadFile} = useUploadFile()

    const _validateFileUpload = (file) => {
        let isValid = true
        if (!IMAGE_TYPES.includes(file.type) && file.type !== SVG_TYPE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'Your uploaded file is not supported',
            })
        }
        if (file.size > MAX_IMAGE_UPLOAD_SIZE && IMAGE_TYPES.includes(file.type)) {
            isValid = false
            toaster({
                type: 'error',
                message: 'Your image exceeded allowed limit for file (JPG, PNG)',
            })
        }
        if (file.size > MAX_SVG_UPLOAD_SIZE && file.type === SVG_TYPE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'Your image exceeded allowed limit for file SVG',
            })
        }
        return isValid
    }

    const _handleChangeFile = async (files) => {
        const file = files[0]
        if (!_validateFileUpload(file)) return
        // check if used capacity greater than limit capacity
        if (capacity && capacity.used > capacity.capacity) {
            return toaster({
                type: 'error',
                message:
                    'The storage limit of design library is reached. Please delete some of your unneeded files to free up spaces',
                width: 500,
            })
        }
        if (capacity && capacity.used > LIMIT_CAPACITY) {
            if (capacity.used + file.size > capacity.capacity) {
                return toaster({
                    type: 'error',
                    message:
                        'The storage limit of design library is reached. Please delete some of your unneeded files to free up spaces',
                    width: 500,
                })
            }
            toaster({
                type: 'warning',
                message:
                    'The storage of design library is almost reached the allowed limit. Please delete some of your unneeded files to free up spaces',
                duration: 10000,
                width: 520,
            })
        }
        dispatch(actionSetRunningImageCompress(true))
        toggle()
        await uploadFile(file)
    }

    return (
        <Fragment>
            <PFBoxUploader
                id="inputUploadArtwork_computer"
                onChange={_handleChangeFile}
                multiple={false}
                isDisabled={false}
                accept={accept || 'image/png, image/jpeg, image/jpg'}
            >
                <img src={ImageUpload} alt="upload" />
                <div className="Text">Click or drag and drop file to upload</div>
            </PFBoxUploader>

            <div className="footerPopup d-flex justify-content-end">
                <Button color="default" onClick={toggle}>
                    Cancel
                </Button>
            </div>
        </Fragment>
    )
}

export default UploadByComputer

