import React from 'react'
import {Input} from 'reactstrap'
import {actionSetCampaignDetail} from '../../../context/SelectedVariantContext/action'

const CampaignTitle = ({title, dispatch}) => {
    const _handleChange = (event) => {
        const {value} = event.target
        dispatch(actionSetCampaignDetail({field: 'title', value}))
    }

    return (
        <div className="CampaignTitle Section">
            <div className="Heading">Title</div>

            <Input id="inputProductTitle" name="title" value={title} onChange={_handleChange} />
        </div>
    )
}

export default React.memo(CampaignTitle)

