import React from 'react'
import NumberFormat from 'react-number-format'

const ProductVariantPricingInput = (props) => {
    const {onChange} = props

    return (
        <div className="PricingEdit d-flex align-items-center">
            <label htmlFor="all-price">Edit price:</label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                </div>
                <NumberFormat
                    id="all-price"
                    name="price"
                    thousandSeparator
                    allowNegative={false}
                    placeholder="0.00"
                    decimalScale={2}
                    onValueChange={({floatValue}) => onChange(floatValue)}
                    onBlur={(e) => e.target.value = ''}
                    className={`form-control`}
                />
            </div>
        </div>
    )
}

export default ProductVariantPricingInput

