import React from 'react'
import VariantImages from '../VariantImages'

const CampaignItem = ({campaign, campaignIndex}) => {
    const {status, url, mockup, title, error} = campaign

    return (
        <div className='CampaignItem'>
            <div className="Wrapper">
                <div className="Left">
                    <div className={`BadgeStatus ${status}`}>{status}</div>
                </div>

                <div className="Center">
                    <div className='ProductTitle'>
                        {
                            !url
                                ?
                                title
                                :
                                <a href={url} target='_blank' rel="noopener noreferrer">{title}</a>
                        }
                    </div>
                </div>

                <div className="Right">
                    <VariantImages
                        parentIndex={campaignIndex}
                        mockup={mockup}
                        numberImageDisplay={3}
                        countImages
                    />
                </div>
            </div>

            {
                error &&
                <div>{error}</div>
            }
        </div>
    )
}

export default CampaignItem
