import Axios from 'axios'
import * as Sentry from '@sentry/react'
import {getAccessToken, getRefreshToken} from './AuthServices'
import {getCurrentStore} from './CurrentStoreServices'

const _store = {
    errorSubscribers: [],
}

const SLOW_THRESHOLD = 15000

const _makeRequest = (createRequest) => async (args) => {
    const shouldStartTransaction = true
    const storeId = getCurrentStore()
    const startTime = new Date().getTime()
    const _headers = args.headers ? args.headers : {}
    const params = args.params ? args.params : {}
    const data = args.data ? args.data : {}
    const method = args.method ? args.method : 'GET'

    const defaultHeaders = {}

    args = {
        ...args,
        headers: {
            ...defaultHeaders,
            ..._headers,
        },
    }

    if (shouldStartTransaction) {
        const transaction = Sentry.startTransaction({
            name: `${method} ${args.url}`,
            op: 'http',
            tags: {
                storeId: storeId,
                endpoint: args.url,
            },
        })

        try {
            const {data} = await createRequest(args)
            const endTime = new Date().getTime()
            const requestDuration = endTime - startTime
            if (requestDuration > SLOW_THRESHOLD) {
                Sentry.captureMessage(
                    `[WARN_SLOW_RESPONSE]: ${method} url=${args.url} has slow response time > ${SLOW_THRESHOLD}ms`,
                    {
                        tags: {
                            storeId: storeId,
                            endpoint: args.url,
                        },
                        extra: {
                            params: params,
                            data: data,
                            time: requestDuration,
                        },
                    }
                )
            }

            transaction.setStatus('Ok')
            transaction.finish()
            return data
        } catch (e) {
            const endTime = new Date().getTime()
            const requestDuration = endTime - startTime

            transaction.setStatus('Error')
            transaction.finish()

            const {response, code} = e
            if (code === 'ECONNABORTED') {
                Sentry.captureMessage(`[ERROR_NETWORK_TIMEOUT]: ${method} url=${args.url} timed out`, {
                    tags: {
                        storeId: storeId,
                        endpoint: args.url,
                        code: code,
                    },
                    extra: {
                        params: params,
                        data: data,
                        time: requestDuration,
                    },
                })
            }

            if (!response || typeof response !== 'object') {
                throw e
            }

            const {status} = response

            if (status) {
                Sentry.captureMessage(`[ERROR_${status}]: ${method} url=${args.url} has error response`, {
                    tags: {
                        storeId: storeId,
                        endpoint: args.url,
                        status: status,
                    },
                    extra: {
                        params: params,
                        data: data,
                        time: requestDuration,
                    },
                })
            }
            throw e
        }
    }
    try {
        const {data} = await createRequest(args)
        return data
    } catch (e) {
        throw e
    }
}

const _makePublicRequest = (createRequest) => async (args) => {
    const _headers = args.headers ? args.headers : {}

    const defaultHeaders = {}

    args = {
        ...args,
        headers: {
            ...defaultHeaders,
            ..._headers,
        },
    }

    try {
        const {data, status} = await createRequest(args)

        return {data, status}
    } catch (e) {
        throw e
    }
}

const _makeAuthRequest = (createRequest) => async (args) => {
    const requestHeaders = args.headers ? args.headers : {}
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()

    let headers = {
        Authorization: `Bearer ${accessToken}`,
        'cache-control': 'no-cache',
    }

    if (refreshToken) {
        headers['X-Refresh-Token'] = refreshToken
    }

    args = {
        ...args,
        headers: {
            ...headers,
            ...requestHeaders,
        },
    }

    try {
        return await _makeRequest(createRequest)(args)
    } catch (error) {
        const {response} = error

        if (!response || typeof response !== 'object') {
            throw error
        }

        const {status, data} = response
        if (status) {
            _store.errorSubscribers.forEach((subscriber) => {
                subscriber({status, data})
            })
        }

        throw error
        // return Promise.reject(error)
    }
}

const _subscribeErrorRequest =
    (instance) =>
    (subscriber = null) => {
        if (!subscriber || typeof subscriber !== 'function') return false
        if (_store.errorSubscribers.indexOf(subscriber) !== -1) return false

        _store.errorSubscribers = [].concat(_store.errorSubscribers, [subscriber])
    }

// eslint-disable-next-line import/no-anonymous-default-export
export default (options = {}) => {
    const baseUrlValidated = options.baseUrl || '/api'
    const headers = Object.assign({}, options.headers)
    const timeoutValidated = options.timeout || 30000

    const instance = Axios.create({
        baseURL: baseUrlValidated,
        timeout: timeoutValidated,
        headers,
        validateStatus: function (status) {
            return status >= 200
        },
        onUploadProgress: options.onUploadProgress,
    })

    const _changeBaseURL = (baseURL) => {
        instance.defaults.baseURL = baseURL
    }

    const _getBaseURL = () => {
        return instance.defaults.baseURL
    }

    const _setDefault = (property, value = null) => {
        instance.defaults[property] = value
    }

    return {
        setDefault: _setDefault,
        getBaseURL: _getBaseURL,
        changeBaseURL: _changeBaseURL,
        makeRequest: _makeRequest(instance),
        makeAuthRequest: _makeAuthRequest(instance),
        makePublicRequest: _makePublicRequest(instance),
        subscribeErrorRequest: _subscribeErrorRequest(instance),
    }
}

