import React, {Component} from 'react'
import TagItem from './TagItem'
import PropTypes from 'prop-types'

class TagsList extends Component {
    render() {
        const {tags, onRemoveTags} = this.props

        return (
            tags.length > 0 && (
                <ul className="TagsList mt-2">
                    {tags.map((tag, index) => {
                        return <TagItem key={`${tag}_${index}`} tag={tag} onRemoveTags={onRemoveTags} />
                    })}
                </ul>
            )
        )
    }
}

TagsList.propTypes = {
    tags: PropTypes.array.isRequired,
    onRemoveTags: PropTypes.func.isRequired,
}

export default TagsList

