import classNames from 'classnames'
import React from 'react'
import {HEATHER_COLORS} from '../../../constant'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {UncontrolledTooltip} from 'reactstrap'
import {actionChangeSelectedAttribute} from '../context/SelectedVariantContext/action'

export default function ProductAttributes() {
    const {
        dispatch,
        state: {mappedAttributes},
    } = useSelectedVariantContext()

    const handleChangeAttributes = (key, data) => {
        dispatch(actionChangeSelectedAttribute({key, data}))
    }

    const _renderAttributes = () => {
        let output = []
        Object.entries(mappedAttributes).forEach(([key, values]) => {
            switch (key) {
                case 'color':
                    output.push(
                        <div key={key} className="ColorAttribute">
                            <div className="Title">Color</div>
                            <div className="GroupStyleColor">
                                <ul className="d-flex flex-wrap">
                                    {values.map((value, index) => {
                                        return (
                                            <li key={index} onClick={() => handleChangeAttributes(key, value)}>
                                                <div
                                                    id={`htx_${index}`}
                                                    className={classNames('ColorItem', {
                                                        active: value.is_selected || false,
                                                    })}
                                                    style={{
                                                        backgroundColor: value.value_code,
                                                        ...(HEATHER_COLORS.includes(value.value_code) && {
                                                            backgroundImage:
                                                                "url('/assets/images/heather_texture.png')",
                                                        }),
                                                    }}
                                                />
                                                <UncontrolledTooltip target={`htx_${index}`}>
                                                    {value.value_text}
                                                </UncontrolledTooltip>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                    break
                case 'label':
                case 'size':
                    output.push(
                        <div key={key} className="OtherAttributes">
                            <div className="GroupStyleAttribute">
                                <div className="Title text-capitalize">{key}</div>
                                <ul className="d-flex flex-wrap">
                                    {values.map((value, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={classNames(`${value.is_selected || false ? 'active' : ''}`)}
                                                onClick={() => handleChangeAttributes(key, value)}
                                            >
                                                {value.value_text}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                    break
                default:
                    break
            }
        })
        return output
    }

    return <div>{_renderAttributes()}</div>
}

