import React, {Component} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Popover, PopoverBody, Spinner} from "reactstrap"
import PropTypes from 'prop-types'

class ConfirmModal extends Component {
    state = {
        loading: false,
        error: '',
    }

    _toggleModal = () => {
        this.props.onClose(false)
    }

    _handleClickOk = () => {
        const {onOk, onClose} = this.props

        if (typeof onClose !== 'function') {
            onClose(false)
            return
        }

        const result = onOk()

        if (!result || !result.then) {
            return result
        }

        this.setState({loading: true})

        result.then(response => {
            onClose(false)
        }).catch(e => {
            this.setState({
                loading: false,
                error: e.message,
            })
        })
    }

    _getFooterContent = () => {
        const {confirmText, cancelText, confirmColor, cancelColor} = this.props
        const {loading} = this.state

        return (
            <>
                {
                    cancelText &&
                    <Button color={cancelColor} onClick={this._toggleModal} className='btn-default'>
                        {cancelText}
                    </Button>
                }
                {
                    confirmText &&
                    <Button color={confirmColor} onClick={this._handleClickOk} disabled={loading}>
                        {
                            loading && <Spinner color='white' size='sm' className='me-2'/>
                        }
                        {confirmText}
                    </Button>
                }
            </>
        )
    }

    render() {
        const {title, message, size, className} = this.props
        const {error} = this.state
        const footerContent = this._getFooterContent()

        return (
            <Modal
                isOpen
                className={`ConfirmModal modal-dialog-centered ${className}`}
                toggle={this._toggleModal}
                size={size}
            >
                {
                    title &&
                    <ModalHeader toggle={this._toggleModal}>{title}</ModalHeader>
                }
                <ModalBody>
                    {message}

                    {
                        error &&
                        <div className='p-3 mt-3 bg-light text-danger'>
                            {error}
                        </div>
                    }
                </ModalBody>
                <ModalFooter>{footerContent}</ModalFooter>
            </Modal>
        )
    }
}

ConfirmModal.defaultProps = {
    title: '',
    message: 'Are you sure?',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    confirmColor: 'primary',
    cancelColor: '',
    className: '',
    size: null,
}

ConfirmModal.propTypes = {
    title: PropTypes.node,
    message: PropTypes.node,
    confirmText: PropTypes.node,
    cancelText: PropTypes.node,
    confirmColor: PropTypes.string,
    cancelColor: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func,
}

class ConfirmPopover extends Component {
    state = {
        loading: false,
        error: '',
    }

    _toggle = () => {
        if (this.state.loading) return
        this.props.onClose(false)
    }

    _getIcon = () => {
        const {type} = this.props
        if (!type) return null

        let icon = ""

        switch (type) {
            case 'success':
                icon = <i className="fas fa-check-circle text-success"/>
                break
            case 'info':
                icon = <i className="fas fa-info-circle text-info"/>
                break
            case 'warning':
                icon = <i className="fas fa-exclamation-circle text-warning"/>
                break
            case 'danger':
            case 'error':
                icon = <i className="fas fa-times-circle text-danger"/>
                break
            default:
                icon = null
        }

        return <span className="me-1">{icon}</span>
    }

    _handleClickOk = () => {
        const {onOk, onClose} = this.props
        const {loading} = this.state

        if (typeof onClose !== 'function') {
            onClose(false)
            return
        }
        if (loading) return

        const result = onOk()

        if (!result || !result.then) {
            onClose(false)
            return result
        }

        this.setState({loading: true})

        result.then(response => {
            onClose(false)
        }).catch(e => {
            this.setState({
                loading: false,
                error: e.message,
            })
        })
    }

    render() {
        const {loading} = this.state
        const {message, id, placement, offset, className, confirmText = 'Yes'} = this.props

        return (
            <Popover
                isOpen
                target={id}
                placement={placement}
                fade={false}
                trigger="legacy"
                toggle={this._toggle}
                offset={offset}
                className={className}
            >
                <PopoverBody>
                    <div className="mb-3 d-flex">
                        {this._getIcon()}{message}
                    </div>
                    <div className="text-right">
                        <Button size="sm" className="btn-default me-2" onClick={this._toggle}>Cancel</Button>
                        <Button size="sm" color="primary" onClick={this._handleClickOk}>
                            {
                                loading && <Spinner color='white' size='sm' className='me-2' />
                            }
                            {confirmText}
                        </Button>
                    </div>
                </PopoverBody>
            </Popover>
        )
    }
}

ConfirmPopover.defaultProps = {
    offset: "0, 0"
}

ConfirmPopover.propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func,
    message: PropTypes.string,
    placement: PropTypes.string,
    offset: PropTypes.string,
    className: PropTypes.string,
    confirmText: PropTypes.string
}

export function confirmModal(props) {
    return new Promise(resolve => {
        let el = document.createElement('div')

        const handleResolve = result => {
            unmountComponentAtNode(el)
            el = null
            resolve(result)
        }

        if (props.display === "popover") {
            render(<ConfirmPopover {...props} onClose={handleResolve} />, el)
        } else {
            render(<ConfirmModal {...props} onClose={handleResolve}/>, el)
        }
    })
}
