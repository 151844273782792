import {createStoreAPI} from './CreateStoreAPIService'

export const fetchPublicStoreSettingsBySlug = (slug) => {
    const api = createStoreAPI({
        getBaseURL: (currentStore, getStoreSetting) => {
            const boMaster = getStoreSetting('backoffice_master_url')
            if (boMaster) {
                return `${boMaster}/api/seller`
            }

            return `${process.env.REACT_APP_BASE_URL}/api/seller`
        },
        storeId: slug,
    })

    return api.makeAuthRequest({
        url: `/stores/by-slug/${slug}/public-settings`,
        method: 'GET',
    })
}

export const authorizeWithStoreSlug = (slug) => {
    const api = createStoreAPI({
        getBaseURL: (currentStore, getStoreSetting) => {
            const boMaster = getStoreSetting('backoffice_master_url')
            if (boMaster) {
                return `${boMaster}/api/seller`
            }

            return `${process.env.REACT_APP_BASE_URL}/api/seller`
        },
        storeId: slug,
    })

    return api.makeAuthRequest({
        url: `/stores/by-slug/${slug}/authorize`,
        method: 'GET',
    })
}
