import React, {useCallback, useEffect, useState} from 'react'
import Slider from 'react-slick'
import {ReactComponent as ChevronLeft} from '../../../../assets/icons/chevron-left.svg'
import {ReactComponent as ChevronRight} from '../../../../assets/icons/chevron-right.svg'
import {ReactComponent as Reload} from '../../../../assets/icons/renew.svg'
import {usePreviewArtworks} from '../../../../hooks/usePreviewArtworks'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {isSubsetAttributes} from '../../context/SelectedVariantContext/helper'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CampaignDesignLoading from '../../upload/tabs/campaign-design/CampaignDesignLoading'
import {Spinner} from 'reactstrap'

const PreviewArtworkContainer = () => {
    const {fetchPreviews, percentage, pending} = usePreviewArtworks()
    const {
        state: {selectedAttribute, previewImages, reloadPreview}
    } = useSelectedVariantContext()

    useEffect(() => {
        fetchPreviews()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [previewSrc, setPreviewSrc] = useState([])
    const [firstLoad, setFirstLoad] = useState(true)

    const loadPreviewImages = useCallback(() => {
        if (!previewImages.length || !selectedAttribute) return []
        setOnImgError(false)
        setFirstLoad(false)

        const selectedAttr = selectedAttribute.attributes.map((attr) => {
            return {type: attr.type, value: attr.value_code}
        })

        const images =
            previewImages.find((preview) => isSubsetAttributes(preview.attributes, selectedAttr))?.images || []
        setPreviewSrc(images)
    }, [previewImages, selectedAttribute])

    useEffect(() => {
        loadPreviewImages()
    }, [loadPreviewImages])

    const [onImgError, setOnImgError] = useState(false)
    const handleImageError = () => {
        setOnImgError(true)
    }

    const handleOnLoad = () => {
        setFirstLoad(true)
    }

    const loading = reloadPreview || (pending && !onImgError)

    return (
        <div className={`PreviewArtworkContainer d-flex`}>
            <div className="PreviewArtworkSlider">
                {percentage !== 0 && loading && (
                    <div className="LoadingContainer text-center w-50 m-auto">
                        <CampaignDesignLoading
                            percentage={percentage}
                            text="Your mockup is generating, please wait..."
                        />
                    </div>
                )}
                <div className={loading ? 'd-none' : 'd-block'}>
                    {onImgError ? (
                        <div className="PreviewArtworkErrorWrapper text-center">
                            <div className="PreviewArtworkError">
                                <div>
                                    <p className="text-danger mb-0">Something went wrong, please try again.</p>
                                    <Reload width={20} height={20} onClick={loadPreviewImages} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Slider dots nextArrow={<ChevronRight />} prevArrow={<ChevronLeft />}>
                            {previewSrc.map((src, index) => (
                                <div key={index} className="Image">
                                    {!firstLoad && (
                                        <div className="ImageFirstLoading text-center">
                                            <Spinner style={{color: '#007bff'}} />
                                        </div>
                                    )}
                                    <img
                                        src={`${src}?timestamp=${new Date().getTime()}`}
                                        onLoad={handleOnLoad}
                                        onError={handleImageError}
                                        alt="Preview"
                                        className={`${!firstLoad ? 'd-none' : 'd-block'}`}
                                    />
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PreviewArtworkContainer

