import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {authorizeWithStoreSlug, fetchPublicStoreSettingsBySlug} from '../../services/SellerServices'
import {setLocalData} from '../../services/StorageServices'
import {toaster} from '../../shared/PFToast'

const MockupGeneratorRedirect = () => {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const env = process.env.NODE_ENV
    const path = env === 'development' ? process.env.REACT_APP_PATH : params.get('path')
    const productId = env === 'development' ? process.env.REACT_APP_PRODUCT_ID : params.get('productId')
    const storeId = env === 'development' ? process.env.REACT_APP_STORE_ID : params.get('storeId')
    const accessToken = env === 'development' ? process.env.REACT_APP_ACCESS_TOKEN : params.get('accessToken')

    useEffect(() => {
        setLocalData('path', '')
        setLocalData('storeId', '')
    }, [])

    const _fetchStoreSettings = async (storeId) => {
        try {
            if (!storeId) return
            const {success, data, message} = await fetchPublicStoreSettingsBySlug(storeId)
            if (!success) throw new Error(message)
            setLocalData('currentStoreSettings', JSON.stringify(data))
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const _authorize = async (storeId) => {
        try {
            if (!storeId) return
            const {success, data, message} = await authorizeWithStoreSlug(storeId)
            if (!success) throw new Error(message)
            setLocalData('storeRoles', JSON.stringify(data.roles))
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const _handleRedirect = async (storeId) => {
        await _fetchStoreSettings(storeId)
        await _authorize(storeId)
        if (productId) {
            // window.open(`${window.location.origin}/${productId}`, '_self')
            navigate(`/${productId}`)
        }
    }

    useEffect(() => {
        setLocalData('path', path)
        setLocalData('accessToken', accessToken)
    }, [path, accessToken])

    useEffect(() => {
        setLocalData('storeId', storeId)
        setLocalData('storeRoles', JSON.stringify([]))
        _handleRedirect(storeId)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, storeId])

    // if (path && productId) {
    //     return (
    //         <div className="LoadingWrapper">
    //             <Spinner style={{color: '#007Bff'}} />
    //         </div>
    //     )
    // }

    return <div></div>
}

export default MockupGeneratorRedirect

