export function useDragAndDropFile() {
    /**
    * @param {React.DragEvent<HTMLDivElement>} e 
    */
    const onDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    /**
     * @param {React.DragEvent<HTMLDivElement>} e 
     * @returns {File}
     */
    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer.files[0];
        return file
    };

    return { onDragOver, onDrop };
}
