import {PATTERN_TYPE} from '../../../../constant'

export const INIT_PRODUCT_DATA = 'INIT_PRODUCT_DATA'
export const CHANGE_SELECTED_LAYER = 'CHANGE_SELECTED_LAYER'
export const UPDATE_LAYER_ATTRIBUTE = 'UPDATE_LAYER_ATTRIBUTE'
export const REMOVE_SELECTED_LAYER = 'REMOVE_SELECTED_LAYER'

export const CHANGE_STAGE_DRAG = 'CHANGE_STAGE_DRAG'
export const CHANGE_STAGE_SCALE = 'CHANGE_STAGE_SCALE'
export const DUPLICATE_LAYER = 'DUPLICATE_LAYER'
export const UNDO_HISTORY = 'UNDO_HISTORY'
export const REDO_HISTORY = 'REDO_HISTORY'
export const CHANGE_ACTIVE_SIDE = 'CHANGE_ACTIVE_SIDE'
export const MOVE_LAYERS = 'MOVE_LAYERS'
export const CHANGE_STATUS_PUBLISH = 'CHANGE_STATUS_PUBLISH'
export const CREATE_STAGE_REF = 'CREATE_STAGE_REF'
export const UPDATE_VARIANT_RETAIL_PRICE = 'UPDATE_VARIANT_RETAIL_PRICE'
export const UPDATE_MOCKUP_PREVIEW_IMAGES = 'UPDATE_MOCKUP_PREVIEW_IMAGES'
export const LOADING_MOCKUP_PREVIEW = 'LOADING_MOCKUP_PREVIEW'
export const UPDATE_MOCKUP_PREVIEW_INDEX = 'UPDATE_MOCKUP_PREVIEW_INDEX'
export const UPDATE_GALLERY_IMAGE = 'UPDATE_GALLERY_IMAGE'
export const UPDATE_MAPPED_ATTRIBUTES = 'UPDATE_MAPPED_ATTRIBUTES'
export const SET_CAMPAIGN_DETAIL = 'SET_CAMPAIGN_DETAIL'
export const SET_RUNNING_IMAGE_COMPRESS = 'SET_RUNNING_IMAGE_COMPRESS'
export const SET_IMAGE_COMPRESS_PROGRESS = 'SET_IMAGE_COMPRESS_PROGRESS'
export const SET_IS_ADMIN = 'SET_IS_ADMIN'

export const PREFIX_LOG = `[mockup-generator]`
export const MAXIMUM_IMAGE_SIZE = 100 * 1024 * 1024
export const MAXIMUM_SVG_SIZE = 20 * 1024 * 1024
export const BYTES = 1024
export const LIMIT_IMAGE = 5
export const ALLOW_EXTENSIONS = ['png', 'jpeg', 'jpg', 'svg']
export const SCALE_FOR_PREVIEW_DEFAULT = 0.25
export const DEFAULT_MOCKUP_COLOR = '#ffffff'
export const ALLOW_ARTWORK_SIDES = ['front', 'back', 'sleeve', 'hood']

export const UPDATE_SIZE_EDITOR_WRAPPER = 'UPDATE_SIZE_EDITOR_WRAPPER'
export const UPDATE_SCREEN_DISPLAY = 'UPDATE_SCREEN_DISPLAY'
export const INIT_APPLIED_VARIANTS = 'INIT_APPLIED_VARIANTS'
export const ADD_TEXT_LAYER = 'ADD_TEXT_LAYER'
export const ADD_IMAGE_LAYER = 'ADD_IMAGE_LAYER'
export const UPDATE_SELECTED_ATTRIBUTE = 'UPDATE_SELECTED_ATTRIBUTE'
export const CHANGE_LINKED_VARIANT = 'CHANGE_LINKED_VARIANT'
export const UPDATE_PREVIEW_IMAGES = 'UPDATE_PREVIEW_IMAGES'
export const UPDATE_PICKER_VARIANTS = 'UPDATE_PICKER_VARIANTS'
export const CHANGE_BACKGROUND_COLOR = 'CHANGE_BACKGROUND_COLOR'
export const RERENDER_STATE = 'RERENDER_STATE'
export const SAVE_CACHE_CAPTURE = 'SAVE_CACHE_CAPTURE'
export const COPY_LAYER = 'COPY_LAYER'
export const PASTE_LAYER = 'PASTE_LAYER'
export const UPDATE_APPLY_ALL_PLACEMENTS = 'UPDATE_APPLY_ALL_PLACEMENTS'
export const UPDATE_IMAGE_LAYER_LOADING = 'UPDATE_IMAGE_LAYER_LOADING'
export const REDUX_DEVTOOL_JUMP = 'REDUX_DEVTOOL_JUMP'

// Effects
export const IMAGE_DEFAULT_EFFECTS = {
    // stroke: '#000000',
    // strokeWidth: 1,
    // shadowColor: '#000000',
    // shadowBlur: 0,
    // shadowOffset: {x: 10, y: 10},
    // shadowOpacity: 1,
    hasDropShadow: false,
    hasPattern: false,
    patternType: PATTERN_TYPE.GRID_PATTERN,
    horizontalSpacing: 0,
    verticalSpacing: 0,
}

export const SCALE_TYPE = {
    MINUS: 'minus',
    PLUS: 'plus',
}

export const CMYK_COLORS = [
    '#ffffff',
    '#000001',
    '#f44e3a',
    '#fe9100',
    '#4c4c4c',
    '#999999',
    '#d9dd00',
    '#a4dd00',
    '#68ccc9',
    '#72d8ff',
    '#ada0ff',
    '#fca0ff',
    '#323232',
    '#7f7f7f',
    '#cccccc',
    '#d33015',
    '#327300',
    '#fcc400',
    '#b1bc00',
    '#67bc00',
    '#15a5a5',
    '#009de0',
    '#7a63ff',
    '#f928ff',
    '#e3e3e3',
    '#666666',
    '#b2b2b2',
    '#9e0400',
    '#c45000',
    '#f99d00',
    '#808900',
    '#184c32',
    '#0c797c',
    '#0060af',
    '#643293',
    '#aa149d',
    // 'transparent',
]

export const MOCKUP_BOTTOM_TOOLTIPS = [
    {
        target: 'mockup_guide',
        title: 'Guideline',
    },
    {
        target: 'btn_minus',
        title: 'Zoom out',
    },
    {
        target: 'btn_plus',
        title: 'Zoom in',
    },
    {
        target: 'background_selector',
        title: 'Change background color',
    },
    {
        target: 'drag_editor',
        title: 'Drag editor',
    },
]
