import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {createProductCombine} from '../../../../helpers/createProductCombine'
import CampaignItem from './CampaignItem'
import classNames from 'classnames'

const ModalPublish = ({
    isOpen,
    onClose,
    product = {},
    personalizedArtworkPayload,
    galleryImage = null,
    variants = [],
}) => {
    const [campaign, setCampaign] = useState({
        title: product.title,
        status: 'Pending',
        error: '',
        url: '',
        mockup: {},
        productId: '',
    })

    useEffect(() => {
        if (!personalizedArtworkPayload?.length) return
        createProductCombine({
            product,
            variants,
            personalizedArtworkPayload,
            galleryImage,
            setCampaign,
        })
    }, [personalizedArtworkPayload, product, galleryImage, variants])

    const isCreating = false
    const disabled = isCreating || campaign.status !== 'Completed'
    const handleClose = () => {
        !isCreating && onClose()
    }
    return (
        <Modal
            centered
            isOpen={isOpen}
            toggle={handleClose}
            backdrop="static"
            className="MockupGeneratorContainer ModalPublish ModalPublishCombineContainer"
        >
            <ModalHeader toggle={handleClose}>Mockup complete</ModalHeader>

            <ModalBody>
                {/* <CountCompleted campaign={campaign} /> */}
                <div className="CampaignItems">
                    <CampaignItem campaignIndex={0} campaign={campaign} />
                </div>
            </ModalBody>

            <ModalFooter>
                <a
                    className={classNames('m-0 align-self-stretch', {disabled})}
                    href={`${process.env.REACT_APP_BASE_URL}/a/orders/create-order?sellingGuideStep=2`}
                >
                    <button
                        className={`btn fs-14 me-2 h-100 fw-500 ${
                            isCreating ? 'btn-secondary' : 'btn-primary'
                        }`}
                        disabled={disabled}
                    >
                        Request Sample
                    </button>
                </a>
                <a
                    className={classNames('m-0 align-self-stretch', {disabled})}
                    href={`${process.env.REACT_APP_BASE_URL}/a/products/${campaign.productId}`}
                >
                    <button disabled={disabled} className="ViewAllProducts">
                        View product
                    </button>
                </a>
            </ModalFooter>
        </Modal>
    )
}

export default ModalPublish

