import {useMemo} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {useEditorSides} from './useEditorSides'

export function useEditorLayers() {
    const {
        state: {selectedAttribute, selectedSide, designs},
    } = useSelectedVariantContext()

    const {isOptionalSide} = useEditorSides()

    const layers = useMemo(() => {
        if (!selectedAttribute?._id || !selectedSide || isOptionalSide) return []

        const design = designs.find((item) => item.linkedAttrIds.includes(selectedAttribute._id))

        return design.side[selectedSide]?.layers || []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttribute?._id, selectedSide, designs])

    return layers
}

