import React, {Component} from 'react'
import {createRoot} from 'react-dom/client'
import {Button, Toast, ToastBody, ToastHeader} from 'reactstrap'
import PropTypes from 'prop-types'

class PFToast extends Component {
    state = {
        height: 0,
    }

    componentDidMount() {
        const {duration, unmountToast} = this.props

        if (duration > 0) {
            setTimeout(() => {
                unmountToast()
            }, duration)
        }
    }

    _getIcon = () => {
        const {type} = this.props

        if (!type) return null

        switch (type) {
            case 'success':
                return (
                    <span className="Icon">
                        <i className="fas fa-check-circle text-success" />
                    </span>
                )
            case 'info':
                return (
                    <span className="Icon">
                        <i className="fas fa-info-circle text-info" />
                    </span>
                )
            case 'warning':
                return (
                    <span className="Icon">
                        <i className="fas fa-exclamation-circle text-warning" />
                    </span>
                )
            case 'danger':
            case 'error':
                return (
                    <span className="Icon">
                        <i className="fas fa-times-circle text-danger" />
                    </span>
                )
            default:
                return null
        }
    }

    render() {
        const {title, message, unmountToast, width} = this.props
        const icon = this._getIcon()

        return (
            <Toast className="d-inline-block shadow" style={{width: `${width}px`}}>
                {title && (
                    <ToastHeader toggle={unmountToast}>
                        {icon}
                        {title}
                    </ToastHeader>
                )}

                <ToastBody>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="message-left d-flex">
                            {!title && icon}
                            <div className="Message align-self-center">{message}</div>
                        </div>
                        {!title && <Button className="align-self-start" close onClick={unmountToast} />}
                    </div>
                </ToastBody>
            </Toast>
        )
    }
}

PFToast.defaultProps = {
    title: '',
    message: '',
    type: '',
    duration: 5000,
    width: 400,
}

PFToast.propTypes = {
    title: PropTypes.string,
    message: PropTypes.node,
    type: PropTypes.string,
    duration: PropTypes.number,
    width: PropTypes.number,
    unmountToast: PropTypes.func.isRequired,
}

export function toaster(config = {}) {
    const configObj = Object.assign({}, config)
    const {placement = 'top'} = configObj

    const toastWrapper = document.querySelector(`#PFToast .Toast_${placement}`)
    let toastElement = document.createElement('div')
    toastElement.classList.add('Wrapper')
    toastWrapper.appendChild(toastElement)

    const unmountToast = () => {
        toastRoot.unmount()
        toastElement.remove()
    }

    const toastRoot = createRoot(toastElement)
    toastRoot.render(<PFToast {...config} unmountToast={unmountToast} />)

    return unmountToast
}

