import { debounce } from 'lodash'
import React, { Fragment, useRef } from 'react'
import { Layer } from 'react-konva'
import { actionChangeSelectedLayer, updateLayerAttribute } from '../../context/SelectedVariantContext/action'
import { handleCalculateDpi } from '../../context/SelectedVariantContext/helper'
import BackgroundLayer from './BackgroundLayer'
import TextLayer from './TextLayer'
import ImageLayer from './ImageLayer'
 
const MockupLayers = ({background, layers, draggable, dispatch, selectedLayer}) => {
    const layerRef = useRef(null)

    return (
        <Fragment>
            <Layer ref={layerRef} listening={!draggable}>
                <BackgroundLayer
                    onClick={() => dispatch(actionChangeSelectedLayer())}
                    imageProps={background}
                    isBackground={true}
                    zIndexDefault={0}
                />
                <BackgroundLayer
                    onClick={() => dispatch(actionChangeSelectedLayer())}
                    imageProps={background}
                    zIndexDefault={1}
                />
                {layers?.map((layer, i) => {
                    const isSelected = layer.id === selectedLayer?.id
                    const props = {
                        key: layer.id,
                        zIndexDefault: i + 2,
                        isSelected,
                        onChange: (layer, updateHistory = true) => dispatch(updateLayerAttribute(layer, updateHistory)),
                        onSelect: () => {
                            !isSelected && dispatch(actionChangeSelectedLayer(layer))
                        },
                        background,

                    }

                    return layer.layerType === 'text' ? (
                        <TextLayer {...props} textProps={layer} />
                    ) : (
                        <ImageLayer
                            {...props}
                            imageProps={layer}
                            dispatch={dispatch}
                            layerRef={layerRef}
                            onChangeSizeImage={debounce((newSize) => {
                                let newAttrs = {...layer, ...newSize}
                                if (layer.imageType !== 'image/svg+xml') {
                                    const dpi = handleCalculateDpi(newAttrs)
                                    newAttrs = {...newAttrs, dpi}
                                    dispatch(actionChangeSelectedLayer(newAttrs))
                                }
                            }, 100)}
                        />
                    )
                })}
                <BackgroundLayer
                    onClick={() => dispatch(actionChangeSelectedLayer())}
                    imageProps={background}
                    showImage={true}
                />
            </Layer>
        </Fragment>
    )
}

export default MockupLayers

