import React from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import MockupGuidelineImg from '../../../assets/images/mockup-guideline.jpg'

const MockupGuideline = (props) => {
    const {isOpen, toggle} = props

    return (
        <Modal className="MockupGuidelineModal" isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>Mockup Guideline</ModalHeader>
            <ModalBody>
                <h5>Resolution/DPI:</h5>
                <ul>
                    <li>
                        For great results, we recommend creating a high quality artwork at least 300 DPI. Avoid low
                        quality images, to produce the best quality with bitmap/raster files, again, always save your
                        design files at the highest resolution possible.
                    </li>
                    <li>
                        Your image may lose detail and appear blurry / pixelated if it is enlarged too much. Remember,
                        resizing or resizing images only reduces the resolution of your images and is best avoided
                        altogether.
                    </li>
                </ul>
                <h5>Artwork Guides:</h5>
                <ul>
                    <li>
                        When creating your design, any important content (text and graphics) should be placed within the
                        Safe Print Area to avoid being trimmed due to the unavoidable shifts that may occur during the
                        production process.
                    </li>
                    <li>
                        Use continuous designs that expand across the whole Bleed Area (grey area). Bleed Area is graded
                        for sizes (sizes range depending on product types) and please allow for a 1/2" tolerance within
                        each size.
                        <img className="w-100" src={MockupGuidelineImg} alt="guideline" />
                    </li>
                    <li>
                        Avoid elements and fonts that subtly blend into the product color (e.g. a dark gray design on a
                        black shirt). These can either blend in completely and not be visible on the product or print a
                        halo with a hard edge.
                    </li>
                </ul>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-default" onClick={toggle}>
                    Close
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default MockupGuideline
