import classNames from 'classnames'
import React, {Fragment, useEffect, useState} from 'react'
import NumberFormat from 'react-number-format'
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc'
import {Button, ButtonGroup, InputGroup, InputGroupText, Label, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as Drag} from '../../../../../assets/icons/drag.svg'
import HorizontalCenter from '../../../../../assets/icons/horizontal_align_center.svg'
import HorizontalLeft from '../../../../../assets/icons/horizontal_align_left.svg'
import HorizontalRight from '../../../../../assets/icons/horizontal_align_right.svg'
import {ReactComponent as ImageThumb} from '../../../../../assets/icons/image-thumb.svg'
import Lock from '../../../../../assets/icons/lock.svg'
import Unlock from '../../../../../assets/icons/lock_open.svg'
import {ReactComponent as TextThumb} from '../../../../../assets/icons/text-thumb.svg'
import {ReactComponent as Trash} from '../../../../../assets/icons/trash-alt.svg'
import VerticalBottom from '../../../../../assets/icons/vertical_align_bottom.svg'
import VerticalCenter from '../../../../../assets/icons/vertical_align_center.svg'
import VerticalTop from '../../../../../assets/icons/vertical_align_top.svg'
import {useEditorBackground} from '../../../../../hooks/useEditorBackground'
import {useEditorLayers} from '../../../../../hooks/useEditorLayers'
import DpiImage from '../../../artwork-editor/DpiImage'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import {
    actionChangeSelectedLayer,
    actionMoveLayers,
    removeLayer,
    updateLayerAttribute,
} from '../../../context/SelectedVariantContext/action'
import {hideToolbar, initTextActualSize} from '../../../context/SelectedVariantContext/helper'
import CampaignDesignTextItem from './CampaignDesignTextItem'
import ImageEffect from './effects/ImageEffect'
import TextEffect from './effects/TextEffect'

const CampaignDesignItem = ({fonts}) => {
    const {
        state: {selectedLayer},
        dispatch,
    } = useSelectedVariantContext()
    const layers = useEditorLayers()
    const background = useEditorBackground()

    const onSortEnd = ({oldIndex, newIndex}) => {
        const sortedLayers = arrayMove(layers.slice().reverse(), oldIndex, newIndex)
        const isForward = newIndex > oldIndex

        const lockedIndicesInRange = layers.reduce((lockedIndices, layer, index) => {
            if (
                layer.isLock &&
                ((isForward && index > oldIndex && index < newIndex) ||
                    (!isForward && index < oldIndex && index > newIndex))
            ) {
                lockedIndices.push(index)
            }
            return lockedIndices
        }, [])

        lockedIndicesInRange.forEach((locked) => {
            arrayMove(sortedLayers, locked, isForward ? locked - 1 : locked + 1)
        })

        dispatch(actionMoveLayers(sortedLayers.slice().reverse()))
    }

    return (
        <div className="CampaignDesignItem">
            <div id="accordion">
                <SortableList
                    helperClass="draggable-item"
                    useDragHandle
                    lockAxis={'y'}
                    lockToContainerEdges={true}
                    onSortEnd={onSortEnd}
                >
                    {layers
                        .slice()
                        .reverse()
                        .map((layer, index) => (
                            <SortableItem
                                key={layer.id}
                                index={index}
                                layer={layer}
                                selectedLayer={selectedLayer}
                                disabled={layer.isLock}
                                background={background}
                                fonts={fonts}
                                dispatch={dispatch}
                            />
                        ))}
                </SortableList>
            </div>
        </div>
    )
}

const DragHandle = SortableHandle(({lock}) => (
    <Drag id="side_text_drag" className={classNames({lock}, 'edit_position_layer')} width={20} height={20} />
))

const SortableList = SortableContainer(({children}) => {
    return <ul>{children}</ul>
})

const SortableItem = SortableElement(({layer, background, selectedLayer, fonts, dispatch}) => {
    const [openAccordion, setOpenAccordion] = useState(selectedLayer?.id)

    useEffect(() => {
        if (selectedLayer) {
            setOpenAccordion(selectedLayer.id)
            const element = document.getElementById(selectedLayer.id)
            if (element) {
                element.scrollIntoView()
            }
        } else {
            setOpenAccordion(null)
        }
    }, [selectedLayer])

    const handleRemoveLayer = () => {
        if (layer?.isLock) return

        dispatch(removeLayer(layer))
    }

    const handleLockLayer = (layer = {}, isLock = false) => {
        layer.isLock = isLock
        isLock && hideToolbar()
        dispatch(updateLayerAttribute(layer))
    }

    const toggleAccordion = (id) => {
        setOpenAccordion((openAccordion) => (openAccordion === id ? null : id))
        dispatch(actionChangeSelectedLayer(openAccordion === id ? null : layer))
    }

    const onChangeAttribute = (value, name) => {
        const floatValue = value && value !== '-' ? parseFloat(value) : ''
        if (layer && name && background) {
            let {scaleX, scaleY, rotation, x, y, fontSize, width, height} = layer

            switch (name) {
                case 'width':
                    width = floatValue
                    break
                case 'height':
                    height = floatValue
                    break
                case 'rotate':
                    rotation = floatValue
                    break
                case 'fontSize':
                    fontSize = floatValue
                    const {width: newWidth, height: newHeight} = initTextActualSize({...layer, fontSize: floatValue})
                    width = newWidth
                    height = newHeight
                    break
                case 'position_left':
                    x = floatValue
                    break
                case 'position_top':
                    y = floatValue
                    break
                case 'scale':
                    scaleX = scaleX < 0 ? floatValue * -1 : floatValue
                    scaleY = scaleY < 0 ? floatValue * -1 : floatValue
                    break
                default:
                    break
            }

            const updatedLayer = {...layer, scaleX, scaleY, rotation, x, y, fontSize, width, height}
            dispatch(updateLayerAttribute(updatedLayer))
        }
    }

    const onChangePosition = (position) => {
        if (layer && background) {
            const {x: bgX, y: bgY, width: bgWidth, height: bgHeight} = background

            const bgX_End = bgX + bgWidth
            const bgY_End = bgY + bgHeight
            const centerBgX = bgWidth / 2 + bgX
            const centerBgY = bgHeight / 2 + bgY

            const clientRect = null // layer.rect

            const realLayerWidth = clientRect ? clientRect.width : Math.abs(layer.width * layer.scaleX)
            const realLayerHeight = clientRect ? clientRect.height : Math.abs(layer.height * layer.scaleY)

            const centerLayerWidth = realLayerWidth / 2
            const centerLayerHeight = realLayerHeight / 2

            let [newX, newY] = (() => {
                switch (position) {
                    case 'horizontal_left':
                        return [bgX + centerLayerWidth, layer.y]
                    case 'vertical_top':
                        return [layer.x, bgY + centerLayerHeight]
                    case 'horizontal_right':
                        return [bgX_End - realLayerWidth + centerLayerWidth, layer.y]
                    case 'vertical_bottom':
                        return [layer.x, bgY_End - realLayerHeight + centerLayerHeight]
                    case 'horizontal_center':
                        return [centerBgX, layer.y]
                    case 'vertical_center':
                        return [layer.x, centerBgY]
                    default:
                        return [layer.x, layer.y]
                }
            })()

            if (layer.x !== newX || layer.y !== newY) {
                dispatch(
                    updateLayerAttribute({
                        ...layer,
                        x: newX,
                        y: newY,
                    })
                )
            }
        }
    }

    const handleKeyDown = (event, name, needRatio = true) => {
        const {value} = event.target
        let floatValue = value ? parseFloat(value) : ''
        if (event.keyCode === 38) {
            // Arrow up
            event.preventDefault()
            floatValue += needRatio ? 1 / background.ratioDefault : 1
        } else if (event.keyCode === 40) {
            // Arrow down
            event.preventDefault()
            floatValue -= needRatio ? 1 / background.ratioDefault : 1
        }
        let {scaleX, scaleY, rotation, x, y, fontSize, width, height} = layer
        switch (name) {
            case 'width':
                width = floatValue
                break
            case 'height':
                height = floatValue
                break
            case 'fontSize':
                fontSize = floatValue < 0 ? 0 : floatValue
                const {width: newWidth, height: newHeight} = initTextActualSize({...layer, fontSize: floatValue})
                width = newWidth + 5
                height = newHeight + 5
                break
            case 'rotate':
                rotation = floatValue
                break
            case 'scale':
                scaleX = floatValue
                scaleY = floatValue
                break
            case 'position_left':
                x = floatValue
                break
            case 'position_top':
                y = floatValue
                break
            default:
                break
        }
        const updatedLayer = {...layer, scaleX, scaleY, rotation, x, y, fontSize, width, height}
        dispatch(updateLayerAttribute(updatedLayer))
    }

    const [imageErr, setImageErr] = useState(false)

    return (
        <li className="toolbar">
            <div className={classNames('card', {active: selectedLayer?.id === layer.id})} id={`card_${layer.id}`}>
                <div className="card-header" id={layer.id}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="HeaderLeft">
                            <div
                                onClick={() => toggleAccordion(layer.id)}
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                {layer.layerType === 'image' ? (
                                    <>
                                        {imageErr || !layer.tinySrc ? (
                                            <ImageThumb
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    minWidth: '40px',
                                                    minHeight: '40px',
                                                }}
                                            />
                                        ) : (
                                            <img
                                                alt=""
                                                src={layer.tinySrc}
                                                onError={(e) => {
                                                    setImageErr(true)
                                                    e.target.onerror = null
                                                }}
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    objectFit: 'contain',
                                                    minWidth: '40px',
                                                    minHeight: '40px',
                                                }}
                                            />
                                        )}
                                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '12px'}}>
                                            <span style={{lineHeight: '20px', fontWeight: 'bold'}}>{layer.text}</span>
                                            <span style={{lineHeight: '20px'}}>
                                                <DpiImage
                                                    showDetail={false}
                                                    layer={selectedLayer?.id === layer.id ? selectedLayer : layer}
                                                />
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <TextThumb width={40} height={40} />
                                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '12px'}}>
                                            <span style={{lineHeight: '20px', fontWeight: 'bold'}}>{layer.text}</span>
                                            <span style={{lineHeight: '20px'}}>Text layer</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="HeaderRight d-flex align-items-center">
                            <DragHandle lock={layer.isLock} />
                            <img
                                alt=""
                                src={Lock}
                                id={`side_unlock_${layer.id}`}
                                className={classNames('LayerIcon ms-2 edit_position_layer', {'d-none': !layer.isLock})}
                                onClick={() => handleLockLayer(layer, false)}
                            />
                            <UncontrolledTooltip placement="top" target={`side_unlock_${layer.id}`}>
                                Unlock
                            </UncontrolledTooltip>
                            <img
                                alt=""
                                src={Unlock}
                                id={`side_lock_${layer.id}`}
                                className={classNames('LayerIcon ms-2', {'d-none': layer.isLock})}
                                onClick={() => handleLockLayer(layer, true)}
                            />
                            <UncontrolledTooltip placement="top" target={`side_lock_${layer.id}`}>
                                Lock
                            </UncontrolledTooltip>
                            <Trash
                                id={`side_delete_${layer.id}`}
                                width={20}
                                height={20}
                                className={classNames('ms-2 edit_position_layer', {lock: layer.isLock})}
                                onClick={() => handleRemoveLayer(layer)}
                            />
                        </div>
                    </div>
                </div>

                <div className={classNames('collapse', {show: openAccordion === layer.id})}>
                    <div className="card-body">
                        {layer.layerType === 'image' && (
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <Label>Width</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            id="width_box"
                                            name="width"
                                            className="form-control layer_edit"
                                            value={layer.width}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            onChange={(event) => {
                                                const {value} = event.target
                                                onChangeAttribute(value, 'width')
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e, 'width')}
                                        />
                                        <InputGroupText>W</InputGroupText>
                                    </InputGroup>
                                </div>
                                <div className="col-sm-6">
                                    <Label>Height</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            id="height_box"
                                            name="height"
                                            className="form-control layer_edit"
                                            value={layer.height}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            onChange={(event) => {
                                                const {value} = event.target
                                                onChangeAttribute(value, 'height')
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e, 'height')}
                                        />
                                        <InputGroupText>H</InputGroupText>
                                    </InputGroup>
                                </div>
                            </div>
                        )}

                        <CampaignDesignTextItem fonts={fonts} selectedLayer={selectedLayer} dispatch={dispatch} />

                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <Label>Rotate</Label>
                                <InputGroup>
                                    <NumberFormat
                                        id="rotate_box"
                                        name="rotate"
                                        className="form-control layer_edit"
                                        value={layer ? layer.rotation : 0}
                                        disabled={layer.isLock}
                                        decimalScale={2}
                                        autoComplete="off"
                                        allowLeadingZeros={false}
                                        onChange={(event) => {
                                            const {value} = event.target
                                            onChangeAttribute(value, 'rotate')
                                        }}
                                        onKeyDown={(e) => handleKeyDown(e, 'rotate', false)}
                                    />
                                    <InputGroupText className="deg-group-text">deg</InputGroupText>
                                </InputGroup>
                            </div>
                            {layer.layerType === 'image' ? (
                                <div className="col-sm-6">
                                    <Label>Scale</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            id="image_scale_box"
                                            name="scale"
                                            className="form-control layer_edit"
                                            value={layer ? layer.scaleX : 0}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            onChange={(event) => {
                                                const {value} = event.target
                                                onChangeAttribute(value, 'scale')
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e, 'scale', false)}
                                        />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </div>
                            ) : (
                                <div className="col-sm-6">
                                    <Label>Font size</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            id="font_size_box"
                                            name="fontSize"
                                            className="form-control layer_edit"
                                            value={layer ? layer.fontSize : 0}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            allowNegative={false}
                                            onChange={(event) => {
                                                const {value} = event.target
                                                onChangeAttribute(value, 'fontSize')
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e, 'fontSize', false)}
                                        />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </div>
                            )}
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <Label>Position left</Label>
                                <InputGroup>
                                    <NumberFormat
                                        id="left_box"
                                        name="position_left"
                                        className="form-control layer_edit"
                                        value={layer.x}
                                        disabled={layer.isLock}
                                        decimalScale={2}
                                        autoComplete="off"
                                        allowLeadingZeros={false}
                                        onChange={(event) => {
                                            const {value} = event.target
                                            onChangeAttribute(value, 'position_left')
                                        }}
                                        onKeyDown={(e) => handleKeyDown(e, 'position_left')}
                                    />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </div>
                            <div className="col-sm-6">
                                <Label>Position top</Label>
                                <InputGroup>
                                    <NumberFormat
                                        name="top_box"
                                        className="form-control layer_edit"
                                        value={layer.y}
                                        disabled={layer.isLock}
                                        decimalScale={2}
                                        allowLeadingZeros={false}
                                        autoComplete="off"
                                        onChange={(event) => {
                                            const {value} = event.target
                                            onChangeAttribute(value, 'position_top')
                                        }}
                                        onKeyDown={(e) => handleKeyDown(e, 'position_top')}
                                    />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <ButtonGroup className="my-2">
                                    <Button
                                        id="align_left"
                                        className="layer_edit"
                                        disabled={layer.isLock}
                                        outline
                                        onClick={() => onChangePosition('horizontal_left')}
                                    >
                                        <img className="LayerIcon" src={HorizontalLeft} alt="" />
                                    </Button>
                                    <Button
                                        id="align_center"
                                        className="layer_edit"
                                        disabled={layer.isLock}
                                        outline
                                        onClick={() => onChangePosition('horizontal_center')}
                                    >
                                        <img className="LayerIcon" src={HorizontalCenter} alt="" />
                                    </Button>
                                    <Button
                                        id="align_right"
                                        className="layer_edit"
                                        disabled={layer.isLock}
                                        outline
                                        onClick={() => onChangePosition('horizontal_right')}
                                    >
                                        <img className="LayerIcon" src={HorizontalRight} alt="" />
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <div className="col-sm-6">
                                <ButtonGroup className="my-2">
                                    <Button
                                        id="align_top"
                                        className="layer_edit"
                                        disabled={layer.isLock}
                                        outline
                                        onClick={() => onChangePosition('vertical_top')}
                                    >
                                        <img className="LayerIcon" src={VerticalTop} alt="" />
                                    </Button>
                                    <Button
                                        id="vertical_center"
                                        className="layer_edit"
                                        disabled={layer.isLock}
                                        outline
                                        onClick={() => onChangePosition('vertical_center')}
                                    >
                                        <img className="LayerIcon" src={VerticalCenter} alt="" />
                                    </Button>
                                    <Button
                                        id="align_bottom"
                                        className="layer_edit"
                                        disabled={layer.isLock}
                                        outline
                                        onClick={() => onChangePosition('vertical_bottom')}
                                    >
                                        <img className="LayerIcon" src={VerticalBottom} alt="" />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            {layer.layerType === 'image' ? (
                                <ImageEffect layer={layer} dispatch={dispatch} />
                            ) : (
                                <TextEffect layer={layer} dispatch={dispatch} />
                            )}
                        </div>
                        <ButtonTooltip layerId={layer.id} />
                    </div>
                </div>
            </div>
        </li>
    )
})

const ButtonTooltip = ({layerId}) => {
    return (
        <Fragment>
            <UncontrolledTooltip placement="top" target="align_left">
                Align left
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="align_center">
                Align center
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="align_right">
                Align right
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="align_top">
                Align top
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="vertical_center">
                Align center
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="align_bottom">
                Align bottom
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="side_text_drag">
                Drag
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target={`side_delete_${layerId}`}>
                Remove
            </UncontrolledTooltip>
        </Fragment>
    )
}

export default CampaignDesignItem

