import classNames from 'classnames'
import React, {useEffect, useRef, useState} from 'react'
import {Image, Layer, Stage} from 'react-konva'
import {Button, Input, InputGroup, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as Minus} from '../../../assets/icons/minus.svg'
import {ReactComponent as Palm} from '../../../assets/icons/palm.svg'
import {ReactComponent as Plus} from '../../../assets/icons/plus.svg'
import {usePreviewArtworks} from '../../../hooks/usePreviewArtworks'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {calcRatioDefault, roundDecimal} from '../context/SelectedVariantContext/helper'
import SwitchBetweenSides from './SwitchBetweenSides'
import {toaster} from '../../../shared/PFToast'

const MockupSafeZone = ({classes}) => {
    const {
        state: {selectedAttribute, editorWrapper},
    } = useSelectedVariantContext()

    /**
     * @type {{current: import('konva').default.Stage}}
     */
    const stageRef = useRef(null)

    const {fetchAreas} = usePreviewArtworks()
    const [loading, setLoading] = useState(true)
    const [images, setImages] = useState(null)
    const [areaData, setAreaData] = useState(null)
    const [scale, setScale] = useState(1)
    const [drag, setDrag] = useState(false)

    useEffect(() => {
        if (!selectedAttribute?._id || !editorWrapper) return
        const fetchArea = async () => {
            const areaData = await fetchAreas({fetchDesign: true})
            setAreaData(areaData)
        }

        fetchArea()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttribute?._id, editorWrapper])

    useEffect(() => {
        /**
         * @type {import('konva').default.Stage}
         */
        const stage = stageRef.current
        if (!areaData || !stage) return
        const {design, config, mockup} = areaData

        const blob = URL.createObjectURL(design)
        const ratioDefault = calcRatioDefault(editorWrapper, config, {distance: 100})

        stage.setAttrs({
            ratioDefault,
            scaleX: ratioDefault,
            scaleY: ratioDefault,
        })
        stage.batchDraw()

        const attr = {
            x: editorWrapper.width / ratioDefault / 2,
            y: editorWrapper.height / ratioDefault / 2,
        }

        const images = [
            {
                ...attr,
                width: config.width,
                height: config.height,
                offsetX: config.width / 2,
                offsetY: config.height / 2,
                src: blob,
            },
        ]

        const safezone = Object.values(mockup.preview)[0]
        if (safezone) {
            images.push({
                ...attr,
                width: safezone.image_width,
                height: safezone.image_height,
                offsetX: safezone.image_width / 2,
                offsetY: safezone.image_height / 2,
                src: safezone.image_path,
            })
        }

        setImages(images)
        setScale(ratioDefault)
        setLoading(false)

        return () => URL.revokeObjectURL(blob)
    }, [areaData, editorWrapper])

    /**
     * Zoom in or out based on mouse wheel event
     * @param {MouseEvent} e - The mouse event
     */
    const onZoom = (e) => {
        /**
         * @type {import('konva').default.Stage}
         */
        const stage = stageRef.current
        // Get the amount of wheel movement
        const {deltaY} = e.evt

        const {ratioDefault} = stage.attrs

        // Get the current position of the mouse pointer on the stage
        const pointer = stage.getPointerPosition()

        const scaleFactor = deltaY < 0 ? 1.2 : 1 / 1.2

        // Calculate the new scale
        const newScaleX = stage.scaleX() * scaleFactor
        const newScaleY = stage.scaleY() * scaleFactor

        // Define scale boundaries
        const minScale = ratioDefault
        const maxScale = ratioDefault * 10

        // Check if new scale is within boundaries
        if (newScaleX < minScale || newScaleX > maxScale || newScaleY < minScale || newScaleY > maxScale) {
            return
        }

        // Calculate the new position of the stage based on the current position of the mouse pointer
        const newX = pointer.x - (pointer.x - stage.x()) * scaleFactor
        const newY = pointer.y - (pointer.y - stage.y()) * scaleFactor

        stage.scale({x: newScaleX, y: newScaleY})
        stage.position({x: newX, y: newY})
        stage.batchDraw()
        setScale(newScaleX)
    }

    /**
     * @param {'increase'|'decrease'} type
     */
    const onScaleStage = (type) => {
        /**
         * @type {import('konva').default.Stage}
         */
        const stage = stageRef?.current
        if (!stage) return

        const ratio = Math.min(stage.scaleX(), stage.scaleY())
        const {ratioDefault} = stage.attrs

        const scaleFactor = type === 'increase' ? 1.3 : 1 / 1.3

        // Calculate the new scale
        let newScale = ratio * scaleFactor
        if (newScale < ratioDefault) {
            newScale = ratioDefault
            stage.position({x: 0, y: 0})
        } else if (newScale > ratioDefault * 10) {
            newScale = ratioDefault * 10
        } else {
            const centerWidth = stage.width() / 2
            const centerHeight = stage.height() / 2

            const newX = centerWidth - (centerWidth - stage.x()) * scaleFactor
            const newY = centerHeight - (centerHeight - stage.y()) * scaleFactor
            stage.position({x: newX, y: newY})
        }

        stage.scale({x: newScale, y: newScale})
        stage.batchDraw()
        setScale(newScale)
    }

    return (
        <div className={classes}>
            <SwitchBetweenSides />
            {loading && (
                <div style={{position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%'}}>
                    <div className="text-primary spinner-border Spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            <div className={`AllAreaStage ${loading ? 'd-none' : 'd-block'}`}>
                <Stage
                    ref={stageRef}
                    width={editorWrapper.width}
                    height={editorWrapper.height}
                    onWheel={onZoom}
                    draggable={drag}
                >
                    <Layer listening={false}>
                        {images &&
                            images.map((image, index) => {
                                return <ImageLayer {...image} key={index} />
                            })}
                    </Layer>
                </Stage>
            </div>

            {!loading && (
                <div className="MockupBottom">
                    <div className="BottomLeft" />
                    <div className="BottomRight d-flex">
                        <div id="dragStage" className={classNames('MockupDrag me-2', {Active: drag})}>
                            <Palm width={20} height={20} onClick={() => setDrag((prev) => !prev)} />
                        </div>
                        <div className="MockupZoom">
                            <InputGroup>
                                <Button
                                    id="btn_minus"
                                    className="btn-minus"
                                    color="default"
                                    onClick={() => onScaleStage('decrease')}
                                >
                                    <Minus width={16} height={16} />
                                </Button>
                                <Input
                                    className="TextRatio"
                                    type="text"
                                    value={`${roundDecimal(scale * 100)}%`}
                                    readOnly
                                />
                                <Button
                                    id="btn_plus"
                                    className="btn-plus"
                                    color="default"
                                    onClick={() => onScaleStage('increase')}
                                >
                                    <Plus width={16} height={16} />
                                </Button>
                            </InputGroup>
                        </div>
                        <UncontrolledTooltip target="dragStage">Drag editor</UncontrolledTooltip>
                        <UncontrolledTooltip target="btn_minus">Zoom out</UncontrolledTooltip>
                        <UncontrolledTooltip target="btn_plus">Zoom in</UncontrolledTooltip>
                    </div>
                </div>
            )}
        </div>
    )
}

const ImageLayer = (props) => {
    const [image, setImage] = useState(null)

    useEffect(() => {
        const img = new window.Image()
        img.crossOrigin = 'Anonymous'
        img.onload = () => {
            setImage(img)
        }
        img.onerror = () => {
            toaster({type: 'error', message: 'Error loading image', duration: 6000})
        }
        img.src = props.src
    }, [props.src])

    return <Image {...props} image={image} />
}

export default MockupSafeZone

