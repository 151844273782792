export const ALLOW_ARTWORK_SIDES = ['Front', 'Back', 'Sleeve', 'Hood']

export const MAX_DRAG_RATIO = 0.9

export const MAX_SCALE_ALLOW = 2.0

export const SCALE_DECIMAL_PLACES = 3

export const MIN_PREVIEW_ARTWORK_SIDE = 600

export const DEFAULT_MOCKUP_COLOR = 333333

export const DEFAULT_ARTWORK_BACKGROUND_COLOR = '#ffffff'

export const DESIGN_SECTION_SQUARE_SIZE = 650

export const DEFAULT_TRANSFORM = {
    translate: [0, 0],
    rotate: 0,
    scale: [1, 1]
}

export const HEATHER_COLORS = ['#f2f2f2', '#3f403f']    // Sport grey vs Dark heather

export const GROUP_COLORS = {
    "light": [
        "Ash",
        "White",
        "Light Blue",
        "Red",
        "Purple",
        "Gold",
        "Orange",
        "Vegas Gold",
        "Kelly Green",
        "Lime",
        "Light Pink",
        "Lemon"
    ],
    "dark": [
        "Black",
        "Dark Heather",
        "Navy",
        "Deep Forest",
        "Forest",
        "Brown",
        "Charcoal Heather",
        "Sport Grey",
        "Asphalt",
        "Fatigue Green"
    ]
}

export const MAX_ARTWORK_PREVIEW_SIZE = 1000

export const TinyMceEditorToolbar = 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | table insertfile image code pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment media'
export const APIKeyTinymceEditor = 'xuzbk8f26n8hblpca4wo2t53ujo00fgwkvxqjrtb8avs1k0l'
export const TinyMceEditorPlugins = 'link image code table advlist lists media'

export const ENV = {
    WEBSITE: 'website',
    BACK_OFFICE: 'backoffice'
}

export const IMAGE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml'
]

export const SVG_TYPE = 'image/svg+xml'

export const MAX_IMAGE_UPLOAD_SIZE = 50*1024*1024

export const MAX_SVG_UPLOAD_SIZE = 20*1024*1024

export const PATTERN_TYPE = {
    GRID_PATTERN: 'GRID_PATTERN',
    HORIZONTAL: 'HORIZONTAL',
    VERTICAL: 'VERTICAL',
    HORIZONTAL_BRICK: 'HORIZONTAL_BRICK',
    VERTICAL_BRICK: 'VERTICAL_BRICK',
}