import {createStoreAPI} from './CreateStoreAPIService'

export const getLinksUploadArtwork = (params) => {
    const api = createStoreAPI({
        getBaseURL: () => {
            return process.env.REACT_APP_NEXT_MOCKUP_GENERATOR_SERVICE
        },
        timeout: 600000,
    })
    return api.makeAuthRequest({
        url: `/file/presigned-url`,
        method: 'GET',
        params,
    })
}

export const uploadTempArtwork = (url, image, contentType = 'image/png', setProgress) => {
    const api = createStoreAPI({
        timeout: 600000,
    })

    return api.makePublicRequest({
        url,
        method: 'PUT',
        data: image, // Binary
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': contentType,
        },
        onUploadProgress: progressEvent => {
            if (typeof setProgress !== 'function') return
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setProgress(percentCompleted)
          }
    })
}

export const getArtworkFonts = () => {
    const api = createStoreAPI({suffix: '/artwork/artwork-templates', timeout: 180000})
    return api.makeAuthRequest({
        url: `/fonts`,
        method: 'GET',
    })
}

export const uploadArtworkFonts = (formData) => {
    const api = createStoreAPI({suffix: '/artwork/artwork-templates', timeout: 180000})
    return api.makeAuthRequest({
        url: '/fonts',
        method: 'POST',
        data: formData,
    })
}

export const uploadArtworkMultiFonts = (formData) => {
    const api = createStoreAPI({suffix: '/artwork/artwork-templates', timeout: 180000})
    return api.makeAuthRequest({
        url: '/fonts/multi',
        method: 'POST',
        data: formData,
    })
}

export const getUsageCapacity = () => {
    const api = createStoreAPI({suffix: '/artwork'})
    return api.makeAuthRequest({
        url: `/design-images/storage`,
        method: 'GET',
    })
}

export const uploadDesignLibrary = (data) => {
    const api = createStoreAPI({suffix: '/artwork', timeout: 180000})
    return api.makeAuthRequest({
        url: '/design-images',
        method: 'POST',
        data,
    })
}

export const getDesignLibraryImage = (imageId) => {
    const api = createStoreAPI({suffix: '/artwork'})
    return api.makeAuthRequest({
        url: `/design-images/${imageId}`,
        method: 'GET',
    })
}

export const getListDesignLibrary = (params) => {
    const api = createStoreAPI({suffix: '/artwork'})
    return api.makeAuthRequest({
        url: `/design-images`,
        method: 'GET',
        params,
    })
}

export const deleteDesignLibraryImage = (imageId) => {
    const api = createStoreAPI({suffix: '/artwork'})
    return api.makeAuthRequest({
        url: `/design-images/${imageId}`,
        method: 'DELETE',
    })
}
