class MockupConstant {
    static DEFAULT_TRANSFORM = {
        x: 0,
        y: 0,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
        keepRatio: true,
        is_lock: false,
    }

    static DEFAULT_TEXT_ELEMENT = {
        ...this.DEFAULT_TRANSFORM,
        text: 'Your text',
        fill: '#000000',
        textColor: '#000000',
        align: 'left',
        fontWeight: '400',
        layerType: 'text',
        fontFamily: 'Roboto',
        fontStyle: '',
        asTyped: '',
        // hasArc: false,
        // hasStroke: false,
        // hasDropShadow: false,
        // stroke: '#000000',
        // strokeType: 'inside',
        // strokeWidth: 1,
        // shadowColor: '#000000',
        // shadowOpacity: 1,
        // shadowBlur: 0,
    }

    static DEFAULT_IMAGE_ELEMENT = {
        ...this.DEFAULT_TRANSFORM,
        allow_extensions: ['png', 'jpg', 'jpeg'],
        dimension: {
            width: 6200,
            height: 6200,
        },
        layerType: 'image',
    }
}

export default MockupConstant
