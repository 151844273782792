import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class PFBoxUploader extends Component {
    state = {
        dragging: false,
    }

    dropRef = React.createRef()

    componentDidMount() {
        const div = this.dropRef.current
        this.dragCounter = 0

        div.addEventListener('dragenter', this._handleDragIn)
        div.addEventListener('dragleave', this._handleDragOut)
        div.addEventListener('dragover', this._handleDrag)
        div.addEventListener('drop', this._handleDrop)
    }

    componentWillUnmount() {
        const div = this.dropRef.current

        div.removeEventListener('dragenter', this._handleDragIn)
        div.removeEventListener('dragleave', this._handleDragOut)
        div.removeEventListener('dragover', this._handleDrag)
        div.removeEventListener('drop', this._handleDrop)
    }

    _handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    _handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter--

        if (this.dragCounter > 0) return

        this.setState({dragging: false})
    }

    _handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    _handleDrop = (e) => {
        const {preventDragging} = this.props
        if (preventDragging) {
            return this.setState({dragging: false})
        }
        e.preventDefault()
        e.stopPropagation()

        this.setState({dragging: false}, () => {
            this.dragCounter = 0
            this.props.onChange(e.dataTransfer.files)
        })
    }

    _handleChangeFile = (e) => {
        const {files} = e.target

        this.props.onChange(files)
    }

    render() {
        const {multiple, id, children, isDisabled, accept = 'image/png, image/jpeg, image/jpg'} = this.props
        return (
            <div
                className={classnames('PFBoxUploader', {Dragging: this.state.dragging, Disabled: isDisabled})}
                ref={this.dropRef}
            >
                <input
                    type='file'
                    accept={accept}
                    multiple={multiple}
                    onChange={this._handleChangeFile}
                    style={{width: 100}}
                    id={id}
                    className="d-none"
                    disabled={isDisabled}
                />
                <label htmlFor={id} id={id} className="d-block mb-0 cursor-pointer">
                    {children || 'Upload'}
                </label>
            </div>
        )
    }
}

PFBoxUploader.defaultProps = {
    multiple: false,
    isDisabled: false,
    accept: 'image/png, image/jpeg, image/jpg, image/gif'
    // listType: 'picture'
}

PFBoxUploader.propTypes = {
    // listType: PropTypes.oneOf(['picture', 'picture-card']),
    id: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    accept: PropTypes.string
}

export default PFBoxUploader

