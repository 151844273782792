import Axios from 'axios'
import Bluebird from 'bluebird'
import JSZip from 'jszip'
import React from 'react'
import {ReactComponent as ImageDownload} from '../../../../assets/icons/download-image.svg'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {isSubsetAttributes} from '../../context/SelectedVariantContext/helper'
import PFButton from '../../../../shared/PFButton'

const PreviewArtworkDownload = () => {
    const {state} = useSelectedVariantContext()
    const {product, previewImages, selectedAttribute} = state

    const _handleDownload = async (type = 'single') => {
        if (type === 'single') {
            const selectedAttr = selectedAttribute.attributes.map((attr) => {
                return {type: attr.type, value: attr.value_code}
            })
            const images =
                previewImages.find((preview) => isSubsetAttributes(preview.attributes, selectedAttr))?.images || []

            const zip = new JSZip()

            await Bluebird.map(images, async (image, index) => {
                const response = await Axios.get(image, {responseType: 'blob'})
                const fileName = `mockup_${index}.jpg`
                const blob = new Blob([response.data], {type: 'image/jpeg'})
                zip.file(fileName, blob)
            })

            zip.generateAsync({type: 'blob'}).then((content) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(content)
                link.download = `${product.title}_mockup.zip`
                link.click()
            })
        } else {
            const images = previewImages.reduce((acc, preview) => [...acc, ...preview.images], [])
            const zip = new JSZip()

            await Bluebird.map(images, async (image, index) => {
                const response = await Axios.get(image, {responseType: 'blob'})
                const fileName = `mockup_${index}.jpg`
                const blob = new Blob([response.data], {type: 'image/jpeg'})
                zip.file(fileName, blob)
            })

            zip.generateAsync({type: 'blob'}).then((content) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(content)
                link.download = `${product.title}_mockup.zip`
                link.click()
            })
        }
    }

    if (!previewImages?.length) return null
    return (
        <div className="PreviewArtworkDownload">
            <PFButton
                buttonText={
                    <div className="d-flex align-items-center">
                        <ImageDownload width={20} className="me-1" />
                        Download
                    </div>
                }
                onClick={() => _handleDownload('single')}
                className="d-flex align-items-center me-3"
            />
            {previewImages.length > 1 && (
                <PFButton
                    buttonText={
                        <div className="d-flex align-items-center">
                            <ImageDownload width={20} className="me-1" />
                            Download all
                        </div>
                    }
                    onClick={() => _handleDownload('multiple')}
                    className="d-flex align-items-center me-3"
                />
            )}
        </div>
    )
}

export default PreviewArtworkDownload

