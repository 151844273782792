import classnames from 'classnames'
import React, {useEffect, useState} from 'react'
import {TabContent, TabPane} from 'reactstrap'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import CampaignDetailsContainer from './campaign-details/CampaignDetailsContainer'
import CampaignDesignContainer from './campaign-design/CampaignDesignContainer'
import MockupImagePreviewContainer from './mockup-image-preview/MockupImagePreviewContainer'
import VariantViewContainer from './variant-view/VariantViewContainer'

const TabsContainer = () => {
    const {
        state: {display},
    } = useSelectedVariantContext()

    const isPreviewMode = display === 'preview'
    const [activeTab, setActiveTab] = useState('1')

    useEffect(() => {
        if (isPreviewMode) {
            setActiveTab('1')
        }
    }, [display, isPreviewMode])

    const _toggle = (tab) => {
        if (isPreviewMode) return
        if (activeTab !== tab) setActiveTab(tab)
    }

    const _renderTabTitle = () => {
        let output = null
        switch (display) {
            case 'design':
                break
            case 'preview':
                output = (
                    <div className="TabTitle mb-3">
                        <div
                            className={classnames('Title Design', {active: activeTab === '1'})}
                            onClick={() => _toggle('1')}
                        >
                            View
                        </div>
                    </div>
                )
                break
            case 'pricing':
                output = (
                    <div className="TabTitle mb-3">
                        <div
                            className={classnames('Title Design', {active: activeTab === '1'})}
                            onClick={() => _toggle('1')}
                        >
                            Mockup image preview
                        </div>

                        <div
                            className={classnames('Title Details', {
                                active: activeTab === '2',
                                disabled: isPreviewMode,
                            })}
                            onClick={() => _toggle('2')}
                        >
                            Campaign details
                        </div>
                    </div>
                )
                break
            default:
                break
        }
        return output
    }

    const _renderTabContent = () => {
        let output = null
        switch (display) {
            case 'design':
                output = <CampaignDesignContainer />
                break
            case 'preview':
                output = <VariantViewContainer />
                break
            case 'pricing':
                output = (
                    <>
                        <TabPane tabId="1">
                            <MockupImagePreviewContainer />
                        </TabPane>
                        <TabPane tabId="2">
                            <CampaignDetailsContainer />
                        </TabPane>
                    </>
                )
                break
            default:
                break
        }
        return output
    }

    return (
        <div className="TabsContainer">
            {_renderTabTitle()}

            <TabContent activeTab={activeTab} className="TabContent">
                {_renderTabContent()}
            </TabContent>
        </div>
    )
}

export default TabsContainer

