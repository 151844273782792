import React from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import TabUploadType from './TabUploadTypes'

const DesignLibraryModal = (props) => {
    const {isOpen, toggle} = props

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static" className="ModalUploadArtwork">
            <ModalHeader toggle={toggle}>Add image</ModalHeader>
            <ModalBody>
                <TabUploadType {...props} />
            </ModalBody>
        </Modal>
    )
}

export default DesignLibraryModal
