import classnames from 'classnames'
import React, {Fragment, useEffect, useState} from 'react'
import {CompactPicker} from 'react-color'
import {Button, ButtonGroup, Input, InputGroup, UncontrolledTooltip} from 'reactstrap'
import {UncontrolledPopover} from 'reactstrap/lib'
import {ReactComponent as Guideline} from '../../../assets/icons/guideline.svg'
import {ReactComponent as Minus} from '../../../assets/icons/minus.svg'
import {ReactComponent as Palette} from '../../../assets/icons/palette.svg'
import {ReactComponent as Palm} from '../../../assets/icons/palm.svg'
import {ReactComponent as Plus} from '../../../assets/icons/plus.svg'
import {ReactComponent as Redo} from '../../../assets/icons/redo.svg'
import {ReactComponent as Undo} from '../../../assets/icons/undo.svg'
import {useEditorBackground} from '../../../hooks/useEditorBackground'
import {useEditorSides} from '../../../hooks/useEditorSides'
import {useEditorStageRef} from '../../../hooks/useEditorStageRef'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionChangeApplyAllPlacements,
    actionChangeSelectedLayer,
    actionChangeStageDrag,
    actionRedo,
    actionUndo,
    actionUpdateBgColor,
} from '../context/SelectedVariantContext/action'
import {CMYK_COLORS, MOCKUP_BOTTOM_TOOLTIPS} from '../context/SelectedVariantContext/constants'
import {hideToolbar, roundDecimal, turnCmykToHex, turnHexToCmyk} from '../context/SelectedVariantContext/helper'
import DpiImage from './DpiImage'
import MockupGuideline from './MockupGuideline'
import PreviewArtworkDownload from './preview/PreviewArtworkDownload'

const MockupBottom = () => {
    const {
        state: {selectedLayer, stageDrag, display, history, applyBgAllSide},
        dispatch,
    } = useSelectedVariantContext()

    const {isOptionalSide} = useEditorSides()

    /**
     * @type {import('konva/types/Stage').Stage}
     */
    const stageRef = useEditorStageRef()
    const ratio = Math.min(stageRef?.scaleX(), stageRef?.scaleY())

    const {ratioDefault = 1, fill: bgColor = 'transparent'} = useEditorBackground()

    const [openGuideline, setOpenGuideline] = useState(false)
    const getWindowWidth = () => {
        const {innerWidth: width} = window
        return width
    }

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const onToggleDragStage = () => {
        if (!stageRef) return
        dispatch(actionChangeStageDrag())
    }

    /**
     * @param {'increase'|'decrease'} type
     */
    const onScaleStage = (type) => {
        if (!stageRef) return
        dispatch(actionChangeSelectedLayer(null))

        const scaleFactor = type === 'increase' ? 1.2 : 1 / 1.2

        // Calculate the new scale
        let newScale = ratio * scaleFactor
        if (newScale < ratioDefault / 5) {
            return
        } else if (newScale > ratioDefault * 10) {
            newScale = ratioDefault * 10
        } else {
            const centerWidth = stageRef.width() / 2
            const centerHeight = stageRef.height() / 2

            const newX = centerWidth - (centerWidth - stageRef.x()) * scaleFactor
            const newY = centerHeight - (centerHeight - stageRef.y()) * scaleFactor
            stageRef.position({x: newX, y: newY})
        }

        stageRef.scale({x: newScale, y: newScale})
        stageRef.batchDraw()
    }

    const handleChangeHistory = (action) => {
        hideToolbar()
        if (action === 'undo') {
            dispatch(actionUndo())
        } else {
            dispatch(actionRedo())
        }
    }

    const handleChange = (color) => {
        let selectedColor = '#ffffff'
        if (color.hex !== 'transparent') {
            // if (color.hex !== '#000000') {
            // convert hex từ color picker sang cmyk để giảm mã màu
            const hexToCmyk = turnHexToCmyk(color.hex)

            // sau đó đổi lại từ cmyk sang hex để set vào layer (vì layer không nhận mã màu cmyk)
            selectedColor = turnCmykToHex(hexToCmyk)
            // }
        }

        dispatch(actionUpdateBgColor(selectedColor))
    }

    const handleChangeApplyAllPlacements = (e) => {
        const {checked} = e.target
        dispatch(actionChangeApplyAllPlacements(checked))
    }

    const toggleGuideline = () => setOpenGuideline(!openGuideline)

    const iconSize = windowWidth < 1400 ? 15 : 20
    const zoomSize = windowWidth < 1400 ? 12 : 16

    return (
        <Fragment>
            {isOptionalSide ? (
                <div></div>
            ) : (
                <Fragment>
                    {display === 'preview' ? (
                        <PreviewArtworkDownload />
                    ) : (
                        <div className="MockupBottom">
                            <MockupGuideline isOpen={openGuideline} toggle={toggleGuideline} />
                            <div className="BottomLeft d-flex align-items-center">
                                {history && display === 'design' && (
                                    <div className="MockupUndo me-2">
                                        <ButtonGroup className="my-2">
                                            <Button
                                                outline
                                                id="undo"
                                                disabled={history.step === 0}
                                                onClick={() => handleChangeHistory('undo')}
                                            >
                                                <Undo width={iconSize} height={iconSize} />
                                            </Button>
                                            <Button
                                                outline
                                                id="redo"
                                                onClick={() => handleChangeHistory('redo')}
                                                disabled={
                                                    history.step === history.logs.length - 1 ||
                                                    history.logs.length === 0
                                                }
                                            >
                                                <Redo width={iconSize} height={iconSize} />
                                            </Button>
                                        </ButtonGroup>
                                        <UncontrolledTooltip placement="top" target="undo">
                                            {history.step === 0 ? 'No history to undo' : 'Undo (Ctrl + Z)'}
                                        </UncontrolledTooltip>
                                        <UncontrolledTooltip placement="top" target="redo">
                                            {history.step === history.logs.length - 1 || history.logs.length === 0
                                                ? 'No history to redo'
                                                : 'Redo (Ctrl + Y)'}
                                        </UncontrolledTooltip>
                                    </div>
                                )}

                                <div
                                    id="mockup_guide"
                                    className="editor_below MockupGuideline pulse ms-2"
                                    onClick={toggleGuideline}
                                >
                                    <Guideline width={20} height={20} />
                                </div>

                                {selectedLayer?.layerType === 'image' && (
                                    <DpiImage layer={selectedLayer} classes="BottomImageDpi ms-2" />
                                )}
                            </div>

                            <div className="BottomRight d-flex">
                                <div className="MockupBackgroundColor me-2">
                                    <div id="background_selector" className="MockupBackgroundColorIcon">
                                        <Palette width={iconSize} height={iconSize} />
                                    </div>
                                    <div className="CboApplyAllPlacements custom-control custom-checkbox">
                                        <input
                                            className="background_color custom-control-input form-check-input mt-0"
                                            id="InputApplyAllPlacements"
                                            type="checkbox"
                                            checked={applyBgAllSide}
                                            onChange={handleChangeApplyAllPlacements}
                                        />
                                        <label
                                            id={'select_background_color'}
                                            className={'background_color'}
                                            htmlFor="InputApplyAllPlacements"
                                        >
                                            Apply to all placements
                                        </label>
                                    </div>
                                </div>
                                <UncontrolledPopover
                                    trigger="legacy"
                                    placement="top"
                                    target="background_selector"
                                    popperClassName="PopupCompactPicker"
                                >
                                    <CompactPicker
                                        color={bgColor}
                                        colors={CMYK_COLORS}
                                        onChange={handleChange}
                                        className="CustomCompactPicker"
                                    />
                                    <div className="TextInfoColorPicker">
                                        Images display in RGB but convert to CMYK for printing. Verify color profile and
                                        adjust print file (if needed) to closely match the mockup.
                                    </div>
                                </UncontrolledPopover>
                                <div
                                    id="drag_editor"
                                    className={classnames('MockupDrag me-2 editor_below', {Active: stageDrag})}
                                    onClick={onToggleDragStage}
                                >
                                    <Palm width={iconSize} height={iconSize} />
                                </div>
                                <div className="MockupZoom">
                                    <InputGroup>
                                        <Button
                                            color="default"
                                            id="btn_minus"
                                            className="btn-minus"
                                            onClick={() => onScaleStage('decrease')}
                                        >
                                            <Minus width={zoomSize} height={zoomSize} />
                                        </Button>
                                        <Input
                                            className="TextRatio"
                                            type="text"
                                            value={`${ratio ? roundDecimal(ratio * 100) : 0}%`}
                                            readOnly
                                        />
                                        <Button
                                            id="btn_plus"
                                            className="btn-plus"
                                            color="default"
                                            onClick={() => onScaleStage('increase')}
                                        >
                                            <Plus width={zoomSize} height={zoomSize} />
                                        </Button>
                                    </InputGroup>
                                </div>
                            </div>
                            {MOCKUP_BOTTOM_TOOLTIPS.map((tooltip) => (
                                <UncontrolledTooltip key={tooltip.target} placement="top" target={tooltip.target}>
                                    {tooltip.title}
                                </UncontrolledTooltip>
                            ))}
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default MockupBottom
