import React from 'react'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import ProductVariantPricingTable from './ProductVariantPricingTable'
import {actionUpdateVariantRetailPrice} from '../context/SelectedVariantContext/action'
import {getErrorRetailPrice} from '../context/SelectedVariantContext/helper'
import ProductVariantPricingInput from './ProductVariantPricingInput'
import pluralize from '../../../helpers/pluralize'

const ProductVariantPricingContainer = () => {
    const {
        dispatch,
        state: {pickerVariants, variants},
    } = useSelectedVariantContext()

    const _onChangeAllVariantPrice = (floatValue) => {
        const updatedVariants = pickerVariants.map((variant) => {
            return {
                ...variant,
                retail_price: floatValue,
                error: getErrorRetailPrice(floatValue, variant.base_cost),
            }
        })

        dispatch(actionUpdateVariantRetailPrice(updatedVariants))
    }

    return (
        <div className={`ProductVariantPricingContainer p-3`}>
            <h6 className="mb-0">Product Variants & Pricing</h6>
            <div className="ProductVariantPricingEdit d-flex align-items-center justify-content-between mb-3">
                <div className="SelectedVariants">
                    <b className="text-primary">
                        {pickerVariants.length} of {variants.length}
                    </b>{' '}
                    variant(s) selected
                </div>
                <ProductVariantPricingInput onChange={_onChangeAllVariantPrice} />
            </div>
            <ProductVariantPricingTable />
            {!!pickerVariants.length && (
                <p>
                    <span className="text-danger">*</span>This will update price for{' '}
                    {pluralize(pickerVariants.length, 'variant', 's')}
                </p>
            )}
        </div>
    )
}

export default ProductVariantPricingContainer

