import React, {useCallback, useEffect, useState} from 'react'
import CampaignItem from './CampaignItem'
import useUploadFile from '../../../../../hooks/useUploadFile'
import CampaignDesignLoading from './CampaignDesignLoading'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import {getArtworkFonts} from '../../../../../services/ArtworkTemplateServices'
import Axios from 'axios'
import PubSub from '../../../Broker'
import {getLocalData} from '../../../../../services/StorageServices'

const CampaignDesignContainer = () => {
    const {pending} = useUploadFile()
    const {
        state: {imageCompressProgress, runningImageCompress},
    } = useSelectedVariantContext()

    const [isMounted, setIsMounted] = useState(false)
    const [googleFonts, setGoogleFonts] = useState([])
    const [fonts, setFonts] = useState({
        customFonts: [],
        googleFonts: [],
    })

    const _getGoogleFonts = async () => {
        if (!isMounted) {
            const googleFonts = await Axios.get(
                `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT_API_KEY}`
            )
            setGoogleFonts(googleFonts.data.items)
            return googleFonts.data.items
        } else {
            return googleFonts
        }
    }

    const _convertToVariantsArrayForExternalLibrary = (variants) => {
        return variants.map((variant) => {
            if (variant === 'italic') return '400i'
            else if (variant === 'regular') return '400'
            else if (variant.includes('italic')) return variant.replace('italic', 'i')
            return variant
        })
    }

    const _renderFontOption = useCallback(async () => {
        let parseCustomFonts = {},
            googleFontItem = {}

        const googleFonts = await _getGoogleFonts()
        googleFonts.forEach((googleFont) => {
            const {family, files, subsets, category, variants} = googleFont
            const convertedVariantsArrayForExternalLibrary = _convertToVariantsArrayForExternalLibrary(variants)

            const replaceAttribute = (i, newI) => {
                files[newI] = files[i]
                delete files[i]
            }

            const replaceToHttps = (string) => string.replace('http://', 'https://')

            for (const i in files) {
                let newIndex = null
                if (i.indexOf('italic') > -1 && i.length > 6) {
                    newIndex = i.replace('italic', 'i')
                } else if (i === 'italic') newIndex = '400i'
                else if (i === 'regular') newIndex = '400'

                newIndex && replaceAttribute(i, newIndex)
                files[newIndex || i] = replaceToHttps(files[newIndex || i])
            }

            googleFontItem[family] = {
                category,
                files,
                variants: convertedVariantsArrayForExternalLibrary.join(','),
                subsets: subsets.join(','),
            }
        })

        const accessToken = getLocalData('accessToken')
        if(!accessToken) {
            const customFonts = !!sessionStorage.getItem('customFonts') ? JSON.parse(sessionStorage.getItem('customFonts')) : {}
            setFonts({
                customFonts: customFonts,
                googleFonts: googleFontItem,
            })
        } else {
            try {
                const {success, data, message} = await getArtworkFonts()

                if (!success) throw new Error(message)

                data.forEach((item) => {
                    parseCustomFonts[item.displayName] = {
                        category: 'other',
                        files: item.path,
                        variants: '',
                        subsets: 'latin',
                    }
                })
                setFonts({
                    customFonts: parseCustomFonts,
                    googleFonts: googleFontItem,
                })
            } catch (e) {
                // window.alert(e.message)
                console.error(e.message)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const subscriber = PubSub.subscribe('LOAD_FONT', () => {
            _renderFontOption()
        })

        return () => {
            PubSub.unsubscribe(subscriber)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsMounted(true)

        _renderFontOption()

        return () => {
            setIsMounted(false)
        }
    }, [_renderFontOption])

    return (
        <div className="CampaignDesignContainer">
            {pending || runningImageCompress ? (
                <CampaignDesignLoading percentage={imageCompressProgress} />
            ) : (
                <div className="CampaignList">
                    {' '}
                    <CampaignItem fonts={fonts} />
                </div>
            )}
        </div>
    )
}

export default CampaignDesignContainer

