import classnames from 'classnames'
import React, {useMemo, useState} from 'react'
import {ReactComponent as ImageIcon} from '../../../../../assets/icons/image.svg'
import {ReactComponent as TextIcon} from '../../../../../assets/icons/text.svg'
import {useDragAndDropFile} from '../../../../../hooks/useDragAndDropFile'
import {useEditorSides} from '../../../../../hooks/useEditorSides'
import useUploadFile from '../../../../../hooks/useUploadFile'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import {actionAddTextLayer} from '../../../context/SelectedVariantContext/action'
import CampaignDesignItem from './CampaignDesignItem'
import DesignLibraryModal from '../../../artwork-editor/design-library/DesignLibraryModal'
import classNames from 'classnames'

const CampaignItem = ({fonts}) => {
    const {onDragOver, onDrop} = useDragAndDropFile()
    const {uploadFile} = useUploadFile()

    /**
     * @type {State} state
     */
    const {
        state: {display},
        dispatch,
    } = useSelectedVariantContext()

    const {isOptionalSide} = useEditorSides()
    const [isOpenImageDesign, setIsOpenImageDesign] = useState(false)

    const isDesignMode = useMemo(() => {
        return display === 'design' && !isOptionalSide
    }, [display, isOptionalSide])

    const onAddText = () => {
        if (!isDesignMode) return
        dispatch(actionAddTextLayer())
    }

    const handleDrop = (e) => {
        if (isOptionalSide) return

        const file = onDrop(e)
        return uploadFile(file)
    }

    const _toggle = () => {
        if (!isDesignMode) return
        setIsOpenImageDesign((prev) => !prev)
    }

    return (
        <div className={classnames('CampaignItem', {active: true})}>
            {isOpenImageDesign && <DesignLibraryModal isOpen={isOpenImageDesign} toggle={_toggle} />}
            {isDesignMode && <CampaignDesignItem fonts={fonts} />}
            <div className="CampaignItemFooter row mb-3">
                <div className="AddImageSection col-sm-6" onDragOver={onDragOver} onDrop={handleDrop}>
                    <label
                        id={'btn_add_image'}
                        className={classNames('add_layer',`${isDesignMode ? '' : 'DisabledLabel'}`)}
                        htmlFor={'btn_add_image'}
                        onClick={_toggle}
                    >
                        <ImageIcon width={20} height={20} className="mt-n1 me-2" />
                        Add image
                    </label>
                    {/* <input
                        type="file"
                        className="add_layer"
                        id={'btn_add_image'}
                        name={'file_images'}
                        multiple={false}
                        hidden
                        onChange={onSelectFile}
                        value={currentFileUrl}
                        accept={ALLOW_EXTENSIONS.map((ext) => `.${ext}`).join(', ')}
                        disabled={!isDesignMode}
                    /> */}
                </div>
                <div className="AddTextSection col-sm-6">
                    <label
                        id="btn_add_text"
                        className={`add_layer ${isDesignMode ? '' : 'DisabledLabel'}`}
                        onClick={onAddText}
                    >
                        <TextIcon width={20} height={20} className="mt-n1 me-2" />
                        Add text
                    </label>
                </div>
            </div>
        </div>
    )
}

export default CampaignItem
