import Konva from 'konva'
import React, {useEffect, useRef} from 'react'
import {Arc, Group, Ring} from 'react-konva'

function SpinnerLayer(props) {
    const {x, y, width, height, offsetX = 0, offsetY = 0} = props

    const groupRef = useRef(null)
    const arcRef = useRef(null)

    useEffect(() => {
        if (!arcRef.current) return
        const angularSpeed = 180 // Degrees per second
        let angle = 0
        const anim = new Konva.Animation((frame) => {
            const angleDiff = (frame.timeDiff * angularSpeed) / 1000
            angle += angleDiff
            arcRef?.current?.rotation(angle)
        }, arcRef?.current?.getLayer())

        anim.start()
        return () => {
            anim.stop()
        }
    }, [width, height, x, y])

    return (
        <>
            <Group ref={groupRef}>
                <Ring
                    x={(x - offsetX + width / 2)}
                    y={(y - offsetY + height / 2)}
                    innerRadius={80}
                    outerRadius={100}
                    fill="#007bff"
                />
                <Arc
                    ref={arcRef}
                    x={(x - offsetX + width / 2)}
                    y={(y - offsetY + height / 2)}
                    innerRadius={80}
                    outerRadius={100}
                    angle={60}
                    rotation={-120}
                    fill="#ffffff"
                />
            </Group>
        </>
    )
}

export default SpinnerLayer

