import React, {useEffect, useRef} from 'react'
import {Stage} from 'react-konva'

import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionChangeSelectedLayer,
    actionCreateStageRefBySide,
    actionRerenderState
} from '../context/SelectedVariantContext/action'
import MockupLayers from './layers'

const MockupStage = ({stage, isActive, isOptionalSide}) => {
    const {
        state: {editorWrapper, stageDrag, selectedLayer},
        dispatch
    } = useSelectedVariantContext()

    const {background, layers} = stage
    const ratioDefault = background?.ratioDefault

    const stageRef = useRef(null)

    useEffect(() => {
        if (isOptionalSide) return
        if (stageRef.current && stage.side) {
            dispatch(
                actionCreateStageRefBySide({
                    stageRef: stageRef.current,
                    side: stage.side,
                    attributeIds: stage.attributeIds
                })
            )
        }
    }, [isOptionalSide, dispatch, stage])

    /**
     * Detaches the currently selected layer if the clicked position is empty
     * @param {MouseEvent} e - The mouse event
     */
    const onDeAttach = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage()
        if (clickedOnEmpty) {
            dispatch(actionChangeSelectedLayer(null))
        }
    }

    /**
     * Zoom in or out based on mouse wheel event
     * @param {MouseEvent} e - The mouse event
     */
    const onZoom = (e) => {
        if (!ratioDefault || isOptionalSide) return

        const stage = stageRef.current
        // Get the amount of wheel movement
        const {deltaY} = e.evt

        // Get the current position of the mouse pointer on the stage
        const pointer = stage.getPointerPosition()

        const scaleFactor = deltaY < 0 ? 1.1 : 1 / 1.1

        // Calculate the new scale
        const newScaleX = stage.scaleX() * scaleFactor
        const newScaleY = stage.scaleY() * scaleFactor

        // Define scale boundaries
        const minScale = ratioDefault / 5
        const maxScale = ratioDefault * 10

        // Check if new scale is within boundaries
        if (newScaleX < minScale || newScaleX > maxScale || newScaleY < minScale || newScaleY > maxScale) {
            return
        }

        // Calculate the new position of the stage based on the current position of the mouse pointer
        const newX = pointer.x - (pointer.x - stage.x()) * scaleFactor
        const newY = pointer.y - (pointer.y - stage.y()) * scaleFactor

        stage.scale({x: newScaleX, y: newScaleY})
        stage.position({x: newX, y: newY})
        stage.batchDraw()
        dispatch(actionRerenderState())
    }

    return (
        <Stage
            ref={stageRef}
            width={editorWrapper.width}
            height={editorWrapper.height}
            scaleX={ratioDefault}
            scaleY={ratioDefault}
            onClick={onDeAttach}
            draggable={stageDrag}
            onWheel={onZoom}
            style={{
                display: isActive ? 'block' : 'none',
                visibility: isActive ? 'visible' : 'hidden'
            }}
            onMouseEnter={(e) => {
                const container = e.target.getStage().container()
                container.style.cursor = stageDrag ? 'pointer' : 'default'
            }}
            onMouseDown={(e) => {
                const container = e.target.getStage().container()
                container.style.cursor = stageDrag ? 'grabbing' : 'default'
            }}
            onMouseUp={(e) => {
                const container = e.target.getStage().container()
                container.style.cursor = stageDrag ? 'pointer' : 'default'
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container()
                container.style.cursor = 'default'
            }}
        >
            {background && (
                <MockupLayers
                    background={background}
                    layers={layers}
                    selectedLayer={selectedLayer}
                    dispatch={dispatch}
                    draggable={stageDrag}
                />
            )}
        </Stage>
    )
}

export default MockupStage

