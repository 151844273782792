import React from 'react'
import ProductVariantPricingTableRow from './ProductVariantPricingTableRow'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'

const ProductVariantPricingTable = () => {
    const {
        dispatch,
        state: {pickerVariants, attributes},
    } = useSelectedVariantContext()

    if (!attributes.length) return null

    return (
        <div className="ProductVariantPricingTable table-responsive">
            <table className="table table-striped table-bordered">
                <thead className="thead-light">
                    <tr>
                        {!!attributes.length &&
                            attributes.map((attribute, index) => {
                                return (
                                    <th key={`attribute_thead_${index}`} className="text-capitalize">
                                        {attribute.name}
                                    </th>
                                )
                            })}
                        <th className="table-col-15">Base Cost</th>
                        <th className="table-col-30">Retail price ($)</th>
                    </tr>
                </thead>
                <ProductVariantPricingTableRow dispatch={dispatch} variants={pickerVariants} />
            </table>
        </div>
    )
}

export default ProductVariantPricingTable

