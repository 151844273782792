import React from 'react'
import NumberFormat from 'react-number-format'
import {actionUpdateVariantRetailPrice} from '../context/SelectedVariantContext/action'
import {getErrorRetailPrice} from '../context/SelectedVariantContext/helper'

const ProductVariantPricingTableRow = (props) => {
    const {dispatch, variants} = props

    const _onChangeVariantPrice = ({floatValue}, index) => {
        const updatedVariants = [...variants]
        const error = getErrorRetailPrice(floatValue, updatedVariants[index].base_cost)
        updatedVariants[index].retail_price = floatValue || ''
        updatedVariants[index].error = error
        dispatch(actionUpdateVariantRetailPrice(updatedVariants))
    }

    return (
        <tbody>
            {!!variants.length &&
                variants.map((variant, index) => {
                    const {sku, attributes, base_cost, retail_price, error} = variant
                    return (
                        <tr key={`row_${sku}`}>
                            {!!attributes.length &&
                                attributes.map((attribute) => {
                                    const {type, value_text, value_code} = attribute
                                    return (
                                        <td key={`cell_${sku}_${type}_${index}`}>
                                            <div className="d-flex align-items-center">
                                                {type === 'color' && (
                                                    <div
                                                        className="me-1"
                                                        style={{
                                                            background: value_code,
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            border: '1px solid #dee2e6',
                                                        }}
                                                    />
                                                )}
                                                {value_text}
                                            </div>
                                        </td>
                                    )
                                })}
                            <td>${base_cost}</td>
                            <td>
                                <NumberFormat
                                    id={`retail_price_${index}`}
                                    name="retail_price"
                                    value={retail_price}
                                    thousandSeparator
                                    allowNegative={false}
                                    decimalScale={2}
                                    placeholder="0.00"
                                    onValueChange={(value) => _onChangeVariantPrice(value, index)}
                                    className={`form-control ${error ? 'invalid-field' : ''}`}
                                />
                                {error && <span className="error-message">{error}</span>}
                            </td>
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default ProductVariantPricingTableRow

