import Bluebird from 'bluebird'
import { generatePersonalizedArtworkV4 } from '../services/MockupGeneratorServices'
import { activateProductPOD, createProductPODV4, deleteProduct, uploadImageGallery } from '../services/ProductServices'
import { searchVariants } from '../services/VariantServices'

export const createProductCombine = async (params) => {
    const {product, personalizedArtworkPayload, variants, galleryImage, setCampaign} = params
    let productIDCreated = ''

    try {
        console.time('create product')
        const {attributes, collections} = product

        const filteredAttributes = attributes.map((attribute) => {
            const filteredValues = attribute.values.filter((value) =>
                variants.some((variant) =>
                    variant.attributes.some(
                        (variantAttribute) =>
                            variantAttribute.name === attribute.name && variantAttribute.value_code === value.code
                    )
                )
            )

            return {...attribute, values: filteredValues}
        })

        const payload1 = {
            ...product,
            collections: Array.isArray(collections) ? collections.map((collection) => collection.value) : [],
            productLine: {
                variants,
                attributes: filteredAttributes,
            },
        }
        delete payload1.mockups

        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            status: 'Uploading',
        }))

        // 1. create a new product
        const resCreateProduct = await createProductPODV4(payload1)
        if (!resCreateProduct.success) throw new Error(resCreateProduct.message)

        productIDCreated = resCreateProduct.data._id

        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            url: resCreateProduct.data.url,
            productId: productIDCreated,
        }))

        // 2. upload artwork personalize for product
        await Bluebird.map(
            personalizedArtworkPayload,
            async (payload) => {
                const formData = new FormData()
                formData.append('product_id', productIDCreated)

                for (const [key, value] of Object.entries(payload)) {
                    formData.append(key, value)
                }

                const resGenPersonalize = await generatePersonalizedArtworkV4(formData)
                if (!resGenPersonalize.success) throw new Error(resGenPersonalize.message)
            },
            {concurrency: 2}
        )

        // 3. upload gallery for product
        if (galleryImage) {
            const resUploadGallery = await uploadImageGallery(productIDCreated, {galleryUrls: [galleryImage]})
            if (!resUploadGallery.success) throw new Error(resUploadGallery.message)
        }

        // 4. activate product
        const resActiveProduct = await activateProductPOD(productIDCreated)
        if (!resActiveProduct.success) throw new Error(resActiveProduct.message)

        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            status: 'Completed',
        }))

        // 5. Request get variant images
        const resSearchVariants = await searchVariants(productIDCreated, [], {limit: 10000})
        if (resSearchVariants?.success) {
            const images = resSearchVariants.data.variants.reduce((accumulator, variant) => {
                const {image_uris = []} = variant
                return accumulator.concat(image_uris)
            }, [])

            setCampaign((prevCampaigns) => ({
                ...prevCampaigns,
                mockup: {
                    total: images.length,
                    images,
                },
            }))
        }
        return true
    } catch (error) {
        deleteProduct(productIDCreated)
        setCampaign((prevCampaigns) => ({
            ...prevCampaigns,
            status: 'Error',
            error: error.message,
        }))
        throw new Error(error)
    } finally {
        console.timeEnd('create product')
    }
}

