import React, {useEffect, useState} from 'react'
import { getProductFacebookPixel } from '../services/ProductServices'

const ProductFacebookPixel = ({productID, collectionIDs = [], tags = [], faceBookPixelInput, faceBookConversionInput}) => {
    const [faceBookData, setFaceBookData] = useState({})
    useEffect(() => {
        const fetchFacebookPixelIDs = async () => {
            try {
                const {data, success} = await getProductFacebookPixel({productID, collectionIDs, tags})
                if (success) {
                    setFaceBookData(data)
                }
            } catch (e) {
                console.log(e)
            }
        }

        fetchFacebookPixelIDs()
        /*eslint-disable */
    }, [collectionIDs.length, tags.length])

    const {collection_facebook_pixel_ids = []} = faceBookData
    let _pixelIDs = collection_facebook_pixel_ids.map(item => {return item && item.fbpxl ? item.fbpxl: ``})
    _pixelIDs = _pixelIDs.filter(item => item)

    let _conversionIDs = collection_facebook_pixel_ids.map(item => {return item && item.fbconversion ? item.fbconversion: ``})
    _conversionIDs = _conversionIDs.filter(item => item)

    return (
        <>
            {faceBookPixelInput}
            <div className='ProductFacebookPixel'>
                {_pixelIDs.length > 0 && <div className='Wrapper'>
                    <div className='Label'>Pixel IDs from Collections:</div>
                    <ul className='Pixels'>
                        {
                            _pixelIDs.map(id => {
                                return <li key={`fbpxl_${id}`} className='Pixel'>{id}</li>
                            })
                        }
                    </ul>
                </div>}
            </div>
            {faceBookConversionInput}
            <div className='ProductFacebookPixel'>
                {_conversionIDs.length > 0 && <div className='Wrapper'>
                        <div className='Label'>Conversion APIs from Collections:</div>
                        <ul className='Pixels'>
                            {
                                _conversionIDs.map(id => {
                                    return <li key={`fbconversion_${id}`} className='Pixel'>{id}</li>
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </>

    )
}

export default ProductFacebookPixel
