import React from 'react'
import {Progress} from 'reactstrap'
import {ReactComponent as HourGlass} from '../../../../../assets/icons/hourglass.svg'

const CampaignDesignLoading = ({text = 'Your file is uploading, please wait...', percentage = 0}) => {

    return (
        <div className="LoadingContainer text-center">
            <HourGlass width={160} height={160} />
            <div className="Percentage mt-4">
                <span className="text-primary">{percentage}%</span>
                <Progress className="my-3" value={percentage} />
            </div>
            <b className="LoadingText fs-14">{text}</b>
        </div>
    )
}

export default CampaignDesignLoading

