import React, {Component} from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'

class InputTags extends Component {
    state = {
        tag: "",
        error: "",
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            tag: value,
            error: "",
        })
    }

    _handleKeyPressInput = e => {
        if (44 === e.which) {
            e.preventDefault()

            this._handleUpdateTags()
        }
    }

    _handleBlurInput = () => {
        this._handleUpdateTags()
    }

    _handleSubmitForm = e => {
        e.preventDefault()

        this._handleUpdateTags()
    }

    _handleUpdateTags = () => {
        const {tag: inputTag} = this.state
        const tagTrimming = inputTag.trim()

        const isValidTag = this._validateEnteredTag(tagTrimming)

        if (isValidTag) {
            this.setState({
                tag: "",
            })

            this.props.onAddTags(tagTrimming)
        }
    }

    _validateEnteredTag = (value) => {
        if (!value) {
            return false
        }

        const {tags} = this.props
        const tagFounded = tags.find(tag => tag === value)

        if (tagFounded !== undefined) {
            this.setState({
                error: `You have already used "${value}"`,
            })
            return false
        }

        return true
    }

    render() {
        const {placeHolder} = this.props
        const {tag, error} = this.state

        return (
            <div className="InputTags">
                <form onSubmit={this._handleSubmitForm}>
                    <div className={className("Wrapper", {"Tooltip1": error})} data-tooltip={error}
                         data-tooltip-location="bottom">
                        <input type="text" value={tag}
                               className={className("form-control InputTag", {"border-danger": error})}
                               onChange={this._handleChangeInput} onKeyPress={this._handleKeyPressInput}
                               onBlur={this._handleBlurInput}
                               id="inputTags" placeholder={placeHolder} autoComplete="off"/>
                    </div>
                </form>
            </div>
        )
    }
}

InputTags.propTypes = {
    tags: PropTypes.array.isRequired,
    onAddTags: PropTypes.func.isRequired,
    placeHolder: PropTypes.string.isRequired,
}

export default InputTags
