import {useMemo} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'

const optionalSides = ['All', 'Safezone']

export function useEditorSides() {
    const {
        state: {selectedAttribute,  selectedSide},
    } = useSelectedVariantContext()

    const sides = useMemo(() => {
        if (!selectedAttribute) return []

        const side = selectedAttribute.safeZone

        return Object.keys(side)
    }, [selectedAttribute])

    const isOptionalSide = useMemo(() => {
        return optionalSides.includes(selectedSide)
    }, [selectedSide])

    return {
        sides,
        isOptionalSide,
        selectedSide,
        optionalSides,
    }
}


