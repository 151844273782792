import {createStoreAPI} from './CreateStoreAPIService'

export const searchVariants = (productId, optionSlugs, queryOption) => {
    const api = createStoreAPI({suffix: '/product'})
    return api.makeAuthRequest({
        url: `/products/${productId}/variants/search`,
        method: 'POST',
        data: {
            options: optionSlugs,
            ...queryOption,
        },
    })
}
