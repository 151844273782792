import React from 'react'
import { useParams } from 'react-router-dom'
import Step2Container from './Step2Container'
import { SelectedVariantProvider } from './context/SelectedVariantContext'

const MockupGeneratorContainer = () => {

    const {productId} = useParams()
    return (
        <SelectedVariantProvider>
            <div className="MockupGeneratorContainer">
                <Step2Container productId={productId} />
            </div>
        </SelectedVariantProvider>
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default MockupGeneratorContainer