import { getLocalData, setLocalData } from "./StorageServices"

const _getCurrentStoreFormStorage = () => {
    const storeId = getLocalData('storeId', '')

    return (storeId + '').trim()
}

const _getStoreSettingsFormStorage = () => {
    const settings = getLocalData('currentStoreSettings', [])
    return Array.isArray(settings) ? settings : []
}

const _store = {
    storeId: _getCurrentStoreFormStorage(),
    settings: _getStoreSettingsFormStorage(),
}

const _setStoreSettings = (settings = []) => {
    const validatedSettings = Array.isArray(settings) ? settings : []

    _store.settings = validatedSettings
    setLocalData('currentStoreSettings', validatedSettings)

    return _store.settings
}

export const getStoreSetting = (key, defaultValue = null) => {
    // const { settings } = _store
    const settings = getLocalData('currentStoreSettings', [])
    const settingsValidated = Array.isArray(settings) ? settings : []
    const setting = settingsValidated.find(setting => setting.key === key)

    if (!setting) return defaultValue

    const { value } = Object.assign({}, setting)

    return value || defaultValue
}

export const getCurrentStoreSettings = () => {
    return _store.settings || []
}

export const getCurrentStore = () => {
    return getLocalData('storeId', '') || _store.storeId
}

export const refreshStoreSettings = (data) => {
    const { storeId } = _store

    if (!storeId) return false

    _setStoreSettings(data)

    return true
}
