import React from 'react'
import CampaignDescription from './CampaignDescription'
import CampaignCollections from './CampaignCollections'
import CampaignTags from './CampaignTags'
import CampaignDisplay from './CampaignDisplay'
import CampaignDisplayFacebookPixel from './CampaignDisplayFacebookPixel'
import CampaignTitle from './CampaignTitle'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import { isBackofficeEnv } from '../../../../../helpers/isBackoffceEnv'

const CampaignDetailsContainer = () => {
    const {
        state: {productDetail},
        dispatch,
    } = useSelectedVariantContext()
    const {
        title,
        is_stealthy,
        is_featured,
        is_shop_hidden,
        is_collection_hidden,
        tags,
        collections,
        description,
        meta = {},
    } = productDetail

    const isBackoffice = isBackofficeEnv()

    return (
        <div className="CampaignDetailsContainer">
            <CampaignTitle title={title} dispatch={dispatch} />
            <CampaignDescription isBackofficeEnv={isBackoffice} description={description} dispatch={dispatch} error={null} />
            {isBackoffice && (
                <>
                    <CampaignCollections collections={collections} dispatch={dispatch} />
                    <CampaignTags tags={tags} dispatch={dispatch} />
                    <CampaignDisplay
                        isStealthy={is_stealthy}
                        isFeatured={is_featured}
                        isShopHidden={is_shop_hidden}
                        isCollectionHidden={is_collection_hidden}
                        dispatch={dispatch}
                    />
                    <CampaignDisplayFacebookPixel
                        pixelID={meta.facebook_pixel_id}
                        facebookConversion={meta.facebook_conversion}
                        error={null}
                        collections={collections}
                        tags={tags}
                        dispatch={dispatch}
                    />
                </>
            )}
        </div>
    )
}

export default CampaignDetailsContainer

