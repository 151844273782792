import React, {Fragment, useEffect, useState} from 'react'
import {Spinner} from 'reactstrap'
import {isBackofficeEnv} from '../../helpers/isBackoffceEnv'
import {useEditorBackground} from '../../hooks/useEditorBackground'
import {getEnvironment, hasRole} from '../../services/AuthServices'
import {getCurrentStore} from '../../services/CurrentStoreServices'
import {getProductCatalogById, getWebsiteProductCatalogById} from '../../services/ProductLinesServices'
import {getLocalData, setLocalData} from '../../services/StorageServices'
import {toaster} from '../../shared/PFToast'
import NavBar from './NavBar'
import MockupBottom from './artwork-editor/MockupBottom'
import MockupEditor from './artwork-editor/MockupEditor'
import PreviewArtworkContainer from './artwork-editor/preview/PreviewArtworkContainer'
import {useSelectedVariantContext} from './context/SelectedVariantContext'
import {actionInitProduct, actionSetIsAdmin} from './context/SelectedVariantContext/action'
import ProductVariantPricingContainer from './product-variant-pricing/ProductVariantPricingContainer'
import ProductVariantContainer from './product-variant/ProductVariantContainer'
import UploadContainer from './upload/UploadContainer'
import classNames from 'classnames'
// import {data} from '../../data'

const Step2Container = ({productId}) => {
    const {
        state: {selectedSide, selectedAttribute, display, product},
        dispatch,
    } = useSelectedVariantContext()
    console.log('product', product)


    const env = getEnvironment()
    const [loading, setLoading] = useState(true)
    const background = useEditorBackground()
    const designLibrary = getLocalData('designLibrary') || []

    useEffect(() => {
        const _fetchProductCatalog = async () => {
            try {
                let response = null
                if (isBackofficeEnv()) {
                    const storeId = getCurrentStore() || ''
                    response = await getProductCatalogById(productId, storeId)
                } else {
                    response = await getWebsiteProductCatalogById(productId)
                    if (designLibrary.length > 0) {
                        const now = new Date()
                        const availableDesigns = designLibrary.filter((design) => {
                            const timeDifference = now - new Date(design.created)
                            const hoursDifference = timeDifference / (1000 * 60 * 60)
                            return hoursDifference <= 1
                        })
                        setLocalData('designLibrary', availableDesigns)
                    }
                }
                const {success, data, message} = response
                if (!success) throw new Error(message)
                const isAdmin = hasRole('admin')
                dispatch(actionSetIsAdmin(isAdmin))
                dispatch(actionInitProduct(data))
            } catch (error) {
                toaster({
                    type: 'error',
                    message: error.message,
                })
            } finally {
                setLoading(false)
            }
        }

        _fetchProductCatalog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, env, dispatch])

    const className = display === 'design' ? 'd-block' : 'd-none'

    return (
        <div className="Step2Container newVersion" style={{overflow: 'hidden'}}>
            {/* <DocTitleByStore title="Mockup generator" /> */}
            {loading ? (
                <div className="LoadingWrapper">
                    <Spinner style={{color: '#007BFF'}} />
                </div>
            ) : product ? (
                <Fragment>
                    <NavBar />
                    <div className={classNames('Wrapper', {Pricing: display === 'pricing'})}>
                        <div id="left-sidebar" className="position-relative pt-3 Left">
                            <ProductVariantContainer className={className} />
                            <PrintFileRequirement
                                selectedSide={selectedSide}
                                selectedAttribute={selectedAttribute}
                                background={background}
                                product={product}
                                className={className}
                            />
                        </div>

                        <div className="Center NewVersion">
                            <div className={className}>
                                <MockupEditor product={product} />
                            </div>
                            {display === 'preview' && <PreviewArtworkContainer display={display} />}
                            {['design', 'preview'].includes(display) && <MockupBottom />}
                            {display === 'pricing' && <ProductVariantPricingContainer display={display} />}
                        </div>

                        <div id="right-sidebar" className="position-relative Right">
                            <UploadContainer />
                        </div>
                    </div>
                </Fragment>
            ) : (
                <Fragment />
            )}
        </div>
    )
}

const PrintFileRequirement = ({selectedSide, selectedAttribute, background, product, className}) => {
    return (
        <Fragment>
            {background && (
                <div className={`PrintFileRequirement ${className}`}>
                    <h4 className="fs-16">Print file requirements</h4>
                    <ul>
                        <li>JPG, JPEG, PNG and SVG file types supported</li>
                        <li>Maximum 50 MB (JPG, PNG) or 20 MB (SVG)</li>
                        {selectedSide === 'All' ? (
                            <li>
                                Print area size {selectedAttribute?.mockup?.preview?.Front?.image_width} x{' '}
                                {selectedAttribute?.mockup?.preview?.Front?.image_height} px (
                                {selectedAttribute?.mockup?.dpi?.minimum} DPI)
                            </li>
                        ) : (
                            <li>
                                Print area size {background.width} x {background.height} px ({background.dpi} DPI)
                            </li>
                        )}

                        {product && product.mockup_and_templates_link && (
                            <li>
                                <a
                                    className="editor_below"
                                    href={product.mockup_and_templates_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    id={'download_template'}
                                >
                                    Click here
                                </a>{' '}
                                to download template & mockup
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </Fragment>
    )
}

export default Step2Container
