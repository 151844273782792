import React, {Component} from 'react'
import InputTags from './InputTags.js'
import TagsList from './TagsList'
import PropTypes from 'prop-types'
import uniqueArray from '../helpers/uniqueArray'

class PFTagInput extends Component {
    state = {
        tags: [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({tags: this.props.value})
        }
    }

    _parseTag = (tag = '') => {
        if (!tag || typeof tag !== 'string') return []

        return tag.split(',')
            .map(text => text.trim())
            .filter(Boolean)
    }

    _handAddTags = (tag) => {
        const newTags = this._parseTag(tag)
        const updatedTags = [].concat(this.state.tags, newTags)
        const uniqueTags = uniqueArray(updatedTags)

        this.setState({tags: uniqueTags}, () => {
            const {onChange} = this.props

            if (typeof onChange === 'function') {
                onChange(this.state.tags)
            }
        })
    }

    _handleRemoveTags = (tag) => {
        this.setState(({tags}) => {
            const tagsUpdated = tags.filter(tagFilter => tag !== tagFilter)

            return {
                tags: tagsUpdated,
            }
        }, () => {
            const {onChange} = this.props

            if (typeof onChange === 'function') {
                onChange(this.state.tags)
            }
        })
    }

    render() {
        const {placeHolder} = this.props
        const {tags} = this.state

        return (
            <div className='TagsInput'>
                <InputTags placeHolder={placeHolder} tags={tags} onAddTags={this._handAddTags}/>

                <TagsList tags={tags} onRemoveTags={this._handleRemoveTags}/>
            </div>
        )
    }
}

PFTagInput.defaultProps = {
    placeHolder: 'Vintage, cotton, summer',
}

PFTagInput.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
}

export default PFTagInput
