import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'

export function useEditorStageRef() {
    const {
        state: {selectedAttribute, selectedSide},
    } = useSelectedVariantContext()

    return selectedAttribute?.ref[selectedSide]
}

