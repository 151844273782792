import React, {useEffect, useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import VariantTable from './VariantTable'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {actionUpdatePickerVariants} from '../../context/SelectedVariantContext/action'

const AvailableProductVariant = ({isOpen, toggle, attributes}) => {
    const {
        dispatch,
        state: {pickerVariants, variants},
    } = useSelectedVariantContext()
    const [selectedVariants, setSelectedVariants] = useState([])
    const [filteredVariants, setFilteredVariants] = useState([])

    const _updateSelectedVariants = () => {
        dispatch(actionUpdatePickerVariants(selectedVariants))
        toggle()
    }

    useEffect(() => {
        const defaultVariantIds = pickerVariants.map((v) => v._id)
        setSelectedVariants(defaultVariantIds)
    }, [pickerVariants])

    return (
        <Modal className="AvailableProductVariant" isOpen={isOpen} toggle={toggle} size="lg" centered backdrop="static">
            <ModalHeader toggle={toggle}>Product Variants & Pricing</ModalHeader>
            <ModalBody>
                <div className="fs-14 mb-3">
                    <span className="fw-500 text-primary">
                        {selectedVariants.length} of {variants.length}
                    </span>{' '}
                    <span className="text-muted">variants selected</span>
                    {/* {selectedVariants.length > 50 && (
                        <p className="text-danger">Please choose less than 50 variants per product.</p>
                    )} */}
                </div>
                <VariantTable
                    variants={variants}
                    attributes={attributes}
                    selectedVariants={selectedVariants}
                    checkedAll={selectedVariants.length === variants.length}
                    filteredVariants={filteredVariants}
                    setFilteredVariants={setFilteredVariants}
                    setSelectedVariants={setSelectedVariants}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    // disabled={selectedVariants.length > 50}
                    disabled={!selectedVariants.length}
                    onClick={_updateSelectedVariants}
                >
                    Update
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default AvailableProductVariant

