import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TagItem extends Component {
    _handleClickRemove = (e) => {
        e.preventDefault()

        const {tag, onRemoveTags} = this.props
        onRemoveTags(tag)
    }

    render() {
        return (
            <li className="TagItem d-inline-block m-1">
                <span className="badge badge-secondary p-2">
                    {this.props.tag}
                    <i className="fas fa-times ms-2 cursor-pointer" onClick={this._handleClickRemove} />
                </span>
            </li>
        )
    }
}

TagItem.propTypes = {
    onRemoveTags: PropTypes.func.isRequired,
    tag: PropTypes.string.isRequired,
}

export default TagItem

